.root {
  margin: 0;
}

.navDropDown {
  border: 0px;
}

.userAvatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 2px solid #c4c4c4;
}

.hrSection {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 14px;
}

.newSup {
  background: #77d1da;
  color: #fff;
  padding: 4px;
  font-size: 9px;
  top: -15px;
  border-radius: 7px;
  font-weight: 600;
}

.welcomeCenter {
  text-align: center;
  font-size: 16px;
}

.headingcolor {
  font-size: 18px;
  color: #77d1da;
  font-weight: 500;
}

.verifiCenter {
  margin: 10px 0;
  font-size: 16px;
  text-align: justify;
}

.center {
  text-align: center;
}

.button {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 16px;
  width: 100%;
  outline: 0;
  border: 1px solid #373277;
  border-radius: 4px;
  background: #373277;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.3333333;
  cursor: pointer;
  text-align: center;
  margin: 19px auto 0 auto;
  width: 150px;
}

.btnPrimary {
  border-color: #77d1da;
  background-color: #77d1da;
  color: #fff;
}

.btnPrimary:hover,
.btnPrimary:focus {
  border-color: #49d5dc;
  background-color: #49d5dc;
  color: #fff;
}

.btnPrimaryBorder {
  border-color: #77d1da !important;
  color: #fff !important;
  background-color: #49d5dc !important;
}

.btnPrimaryBorder:hover,
.btnPrimaryBorder:focus {
  border-color: #49d5dc;
  color: #fff;
  background-color: #49d5dc;
}

.btnSmall {
  padding: 8px 0px;
}

.errorMessage {
  margin-bottom: 5px;
  display: block;
  color: #ff0000;
  font-size: 14px;
  font-weight: normal;
}

.noteInput {
  height: 44px;
}

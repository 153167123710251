@import '../variables.css';

/** RentAll - Begin **/

.formGroup {
  margin-bottom: 6px;
}

.button {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 16px;
  width: 100%;
  outline: 0;
  border: 1px solid #373277;
  border-radius: 4px;
  background: #373277;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.3333333;
  cursor: pointer;
}

.button:hover {
  background: rgba(54, 50, 119, 0.8);
}

.button:focus {
  border-color: #0074c2;
  box-shadow: 0 0 8px rgba(0, 116, 194, 0.6);
}

.facebook {
  border-color: #3b5998;
  background: #3b5998;
  border-radius: 4px;
  text-decoration: none;
  composes: button;
}

.facebook:hover,
.facebook:focus {
  background: #2d4373;
  color: #ffffff;
  text-decoration: none;
}

.linkedin {
  border-color: #0077b5;
  background: #0077b5;
  border-radius: 4px;
  text-decoration: none;
  composes: button;
}

.linkedin:hover,
.linkedin:focus {
  background: #1a47aa;
  color: #ffffff;
  text-decoration: none;
}

.google {
  border-color: #dd4b39;
  background: #dd4b39;
  border-radius: 4px;
  text-decoration: none;
  composes: button;
}

.google:hover,
.google:focus {
  background: #c23321;
  color: #ffffff;
  text-decoration: none;
}

.icon {
  display: inline-block;
  margin: 1px 0px 0px 0px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  float: left;
  fill: currentColor;
}

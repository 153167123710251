.buttonContainer {
  width: auto;
  bottom: 0;
  padding: 10px;
  margin-bottom: 80px;
  display: flex;
  justify-content: flex-end;
  z-index: 9999;
}

.button {
  color: #FFFFFF;
  background: #FFFFFF;
  background-color: #77d1da;
  border: none;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  font-family: Arial;
  text-decoration: none;
  border-radius: 25px;
  position: fixed;
  padding: 10px;
  margin-bottom: 26px;
  margin-left: 20px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  top: 90%;
}

.buttonRooms {
  color: #FFFFFF;
  background: #FFFFFF;
  background-color: #77d1da;
  border: none;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  font-family: Arial;
  text-decoration: none;
  border-radius: 25px;
  padding: 10px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  z-index: 9999;
}


@media screen and (max-width: 1199px) {
  .buttonContainer {
    top: 50%;
    justify-content: flex-start;
    bottom: inherit;
  }
}

@import '../variables.css';

.root {
  padding: 0px 15px 15px;
}

.container {
  margin: 0 auto;
  padding: 0px;
  min-height: 50vh;
  overflow: hidden;
}

/** RentAll - Begin **/

.logInModalContainer {
  max-width: 568px;
  border-radius: 0px !important;
}

.logInModalBody {
  padding: 0px;
}

.wishListTitle {
  font-weight: 700 !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  word-wrap: break-word !important;
  font-family: inherit;
  font-size: 28px !important;
  line-height: 32px !important;
  letter-spacing: 0.6px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  color: #484848 !important;
}

.lineThrough {
  position: relative;
  z-index: 1;
  display: block;
  margin: 12px 0px;
  width: 100%;
  color: #767676;
  text-align: center;
  font-size: 14px;
}

.lineThrough::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  margin-top: -5px;
  margin-left: -20px;
  width: 40px;
  height: 10px;
  background-color: #fff;
  content: '';
}

.lineThrough::after {
  position: absolute;
  top: 49%;
  z-index: -2;
  display: block;
  width: 100%;
  border-bottom: 1px solid #ddd;
  content: '';
}

.formGroup {
  margin-bottom: 6px;
}

.formSection {
  overflow: hidden;
}

.noPadding {
  padding: 0px;
}

.textAlignRight {
  text-align: right;
}

.textAlignLeft {
  text-align: left;
}

.modalCaptionLink {
  color: #77d1da;
}

.modalCaptionLinkLarge {
  font-size: 16px;
  line-height: 32px;
}

.modalCaptionLink:hover,
.modalCaptionLink:focus {
  color: #77d1da;
  text-decoration: underline;
}

.button {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 16px;
  width: 100%;
  outline: 0;
  border: 1px solid #373277;
  border-radius: 4px;
  background: #373277;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.3333333;
  cursor: pointer;
}

.btnPrimary {
  border-color: #77d1da;
  background-color: #77d1da;
  color: #fff;
}

.btnPrimary:hover,
.btnPrimary:focus {
  border-color: #49d5dc;
  background-color: #49d5dc;
  color: #fff;
}

.btnPrimaryBorder {
  border-color: #77d1da;
  color: #77d1da;
  background-color: #fff;
}

.btnPrimaryBorder:hover,
.btnPrimaryBorder:focus {
  border-color: #49d5dc;
  color: #49d5dc;
  background-color: #fff;
}

.btnSmall {
  padding: 5px 0px;
}

.footerContainer {
  right: 0px;
  left: 0px;
  background-color: #ffffff;
  bottom: 0px;
  top: calc(100% - 128px);
  box-shadow: 0 -8px 20px -6px rgba(0, 0, 0, 0.2);
  overflow-y: hidden;
  padding-right: 15px;
  padding-left: 15px;
}

.linkContainer {
  width: 100%;
  background: transparent;
  border: 0px;
  cursor: pointer;
  text-align: left;
  padding: 20px 0px;
  position: relative;
  float: left;
  display: block;
}

@media screen and (max-width: 640px) {
  .logInModalContainer {
    margin: 0px auto;
  }
}

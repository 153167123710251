.root {
  margin: 0;
}

.link {
  display: inline-block;
  padding: 3px 8px;
  text-decoration: none;
  font-size: 1.125em;
}

.link,
.link:active,
.link:visited {
  color: rgba(255, 255, 255, 0.6);
}

.link:hover {
  color: rgba(255, 255, 255, 1);
}

.highlight {
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.15);
  color: #fff;
}

.highlight:hover {
  background: rgba(0, 0, 0, 0.3);
}

.spacer {
  color: rgba(255, 255, 255, 0.3);
}

.newSup {
  background: #77d1da;
  color: #fff;
  padding: 4px;
  font-size: 9px;
  top: -15px;
  border-radius: 4px;
  font-weight: 600;
}

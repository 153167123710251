@import '../../variables.css';

.root {
  padding: 0px 15px 15px;
}

.container {
  margin: 0 auto;
  padding: 0px;
  min-height: 50vh;
  overflow: hidden;
}

/** RentAll - Begin **/

.logInModalContainer {
  max-width: 420px;
}

.logInModalBody {
  padding: 0px;
}

.lineThrough {
  position: relative;
  z-index: 1;
  display: block;
  margin: 12px 0px;
  width: 100%;
  color: #767676;
  text-align: center;
  font-size: 14px;
}

.lineThrough::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  margin-top: -5px;
  margin-left: -20px;
  width: 40px;
  height: 10px;
  background-color: #fff;
  content: '';
}

.lineThrough::after {
  position: absolute;
  top: 49%;
  z-index: -2;
  display: block;
  width: 100%;
  border-bottom: 1px solid #ddd;
  content: '';
}

.formGroup {
  margin-bottom: 6px;
}

.formSection {
  overflow: hidden;
}

.noPadding {
  padding: 0px;
}

.textAlignRight {
  text-align: right;
}

.textAlignLeft {
  text-align: left;
}

.modalCaptionLink {
  color: #77d1da;
}

.modalCaptionLinkLarge {
  font-size: 16px;
  line-height: 32px;
}

.modalCaptionLink:hover,
.modalCaptionLink:focus {
  color: #77d1da;
  text-decoration: underline;
}

.button {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 16px;
  width: 100%;
  outline: 0;
  border: 1px solid #373277;
  border-radius: 4px;
  background: #373277;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.3333333;
  cursor: pointer;
}

.btnPrimary {
  border-color: #77d1da;
  background-color: #77d1da;
  color: #fff;
}

.btnPrimary:hover,
.btnPrimary:focus {
  border-color: #49d5dc;
  background-color: #49d5dc;
  color: #fff;
}

.btnPrimaryBorder {
  border-color: #77d1da;
  color: #77d1da;
  background-color: #fff;
}

.btnPrimaryBorder:hover,
.btnPrimaryBorder:focus {
  border-color: #49d5dc;
  color: #49d5dc;
  background-color: #fff;
}

.btnSmall {
  padding: 5px 0px;
}

/** Common **/
.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/** Common **/
/** Home slider Item - Start **/
/* .imgContainer {
    position: relative;
    width: 100%;
} */

.textContainer {
  width: calc(100% - 120px) !important;
  display: inline-block !important;
  vertical-align: top !important;
  height: 80px !important;
}

.textOverflow {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.listTitleText {
  font-weight: normal !important;
  word-wrap: break-word !important;
  font-family: inherit;
  font-size: 19px !important;
  line-height: 24px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #484848 !important;
}

.imgContainer {
  padding-right: 16px !important;
  display: inline-block !important;
  vertical-align: top !important;
  width: 120px !important;
  height: 80px !important;
}

.subTexContainer {
  word-wrap: break-word !important;
  font-family: inherit;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: 0.2px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #484848 !important;
}

.textBottom {
  margin-bottom: 8px;
}

.parent {
  background: #d8d8d8 none repeat scroll 0 0;
  padding-top: 66.6667%;
  position: relative;
}

.children {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.content {
  height: 100%;
  width: 100%;
  position: relative;
}

.imageContent {
  width: 100% !important;
  height: 100% !important;
  background-size: cover !important;
  background-position: center center !important;
}

.infoContainer {
  padding-top: 8px;
}

.linkContainer,
.linkContainer:hover {
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.infoText {
  color: #484848;
  font-family: inherit;
  margin: 0px;
  word-wrap: break-word;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding-top: 0px;
  padding-bottom: 0px;
  display: inline;
}

.infoReview {
  font-size: 15px;
}

.infoTitle {
  font-size: 15px;
  margin-top: 8px;
}

.infoDesc {
  font-weight: 200;
  font-size: 14px;
  color: #767676;
}

.space1 {
  margin-bottom: 6px;
}

.textStrong {
  font-weight: bold;
}

.reviewStar {
  display: inline;
  position: relative;
  float: left;
}

.reviewText {
  padding: 5px;
  color: #484848;
}

.roomTitleBlock {
  display: inline-block;
  margin: 0px 10px 0px 0px;
}

.instantIcon {
  color: #ffb400;
  height: 16px;
  width: 16px;
}

/** Home slider Item - Start **/

@media screen and (max-width: 640px) {
  .logInModalContainer {
    margin: 0px auto;
  }
}

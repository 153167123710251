/* @import url('https://fonts.googleapis.com/css?family=Lato'); */

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,700i,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Kaushan+Script');

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-weight: 100;
  scroll-behavior: smooth;
}

body,
optgroup,
select,
textarea,
button {
  font-family: 'Lato', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Lato', sans-serif !important;
}

body {
  overflow-x: hidden;
}

select {
  background-image: url('assets/chevron-down.svg') !important;
  background-repeat: no-repeat;
  background-position: 96% 10px;
  background-size: 25px 25px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* input{
	webkit-user-select: auto;
} */

.fixedHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #000;
  transition: all 0.2s ease !important;
}

.searchHeader {
  float: right;
  width: 30%;
  padding: 8px 0 0 0;
  display: none;
}

.fixedHeader .searchHeader {
  display: block;
}

select::-ms-expand {
  display: none;
}

.rentAllHeader.navbar-default {
  background: #fff;
}

.headerHeight.navbar-default {
  min-height: 87px;
  padding-bottom: inherit !important;
}

.cityPageHeader .headerHeight.navbar-default {
  padding-bottom: 10px;
}

.kaushanFont {
  font-family: 'Kaushan Script', cursive !important;
}

.rentAllHeader.navbar-default .navbar-nav>li>a,
.rentAllHeader.navbar-default .navbar-nav>li>form>button {
  color: #484848;
  line-height: 60px;
  height: 67px;
  vertical-align: middle;
  padding: 0px 12px;
  background: #fff;
  font-size: 14px;
  font-family: inherit;
  font-weight: bold;
}

.rentAllHeader .navbar-right {
  padding: 11px 0 0 0;
  margin-right: -6%;
}

.searchPageHeader .navbar-right {
  padding: 11px 0 0 0;
  margin-right: 0px !important;
}

.rentAllHeader .navbar-brand>img {
  margin: 10px 0 0 0 !important;
  /* max-width: 100px;
max-height: 55px; */
}

.adimnHeader .navbar-brand>img {
  margin: 10px 0 0 0 !important;
  /* max-width: 80px;
max-height: 45px; */
}

.rentAllAdminHeader.navbar-default .navbar-nav>li>a,
.rentAllAdminHeader.navbar-default .navbar-nav>li>form>button {
  color: #484848;
  line-height: 45px;
  vertical-align: middle;
  display: inline;
  padding: 0px 19px;
  background: #fff;
  font-size: 14px;
  font-family: inherit;
}

.rentAllAdminHeader.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover,
.rentAllAdminHeader.navbar-default .navbar-nav>li>form>button:focus,
.navbar-default .navbar-nav>li>form>button:hover {
  text-decoration: none;
  color: #484848;
}

.rentAllHeader.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover,
.rentAllHeader.navbar-default .navbar-nav>li>form>button:focus,
.navbar-default .navbar-nav>li>form>button:hover {
  text-decoration: none;
  color: #484848;
}

.rentAllHeader.navbar-default .navbar-nav>.open>a,
.rentAllHeader.navbar-default .navbar-nav>.open>a:focus,
.rentAllHeader.navbar-default .navbar-nav>.open>a:hover {
  background: #fff;
}

.rentAllHeader .dropdown-menu {
  padding: 0px;
  margin: 0px;
  min-width: 282px;
  max-width: 460px;
  border-radius: 0 0 2px 2px;
  border-width: 1px 1px 1px 1px;
  top: 66px;
  right: -15px;
}

.rentAllHeader .dropdown-menu>li>a,
.rentAllHeader .dropdown-menu>li>form>button {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  color: #484848;
  border-bottom: 1px solid #dce0e0;
  position: relative;
  transition: 0.15s all ease;
  font-family: inherit;
  width: 100%;
  text-align: left;
  margin: 0px;
  text-decoration: none;
  line-height: 1.42857143;
  display: block;
}

.rentAllHeader .dropdown-menu>li:last-child>a,
.rentAllHeader .dropdown-menu>li:last-child>form>button {
  border-bottom: 0px;
}

.rentAllHeader .dropdown-menu>li>a:hover,
.rentAllHeader .dropdown-menu>li>form>button:hover {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 20px;
  background-color: #f5f5f5;
  text-decoration: none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: transparent;
  opacity: 1;
}

.ListHeader.navbar-default .navbar-nav>li>a {
  color: #484848;
  font-size: 19px;
  font-weight: normal;
  line-height: 75px;
  height: 72px;
  vertical-align: middle;
  padding: 0px 19px;
  background: #fff;
  border-left: 1px solid #dce0e0;
}

.becomeForm .form-control {
  height: 46px;
  border: none;
}

.verifiInfoBg,
.panel-default>.panel-heading {
  color: #555;
  background-color: rgba(235, 235, 235, 1);
  border-color: rgba(235, 235, 235, 1);
  font-weight: bold;
  border-radius: 4px;
}

.innerMap .icheckbox_minimal-blue,
.iradio_minimal-blue {
  margin: 3px 0 0 0;
}

.mapAlignment .nopadding {
  padding: 0 8px 8px 12px !important;
}

.noUnderLine a {
  text-decoration: none !important;
}

.contactModel .modal-content {
  height: 465px;
}

.contactModel .modal-title {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
}

.contactModel .modal-header {
  border-bottom: none;
}

.contactModel .modal-body {
  margin: 10px 0 0 0;
}

.contactModel .close {
  font-size: 28px;
}

.listingButtonTop {
  margin: 60px 0 0 0 !important;
}

.progressBarCss .progress {
  height: 15px;
  margin-bottom: 20px;
  overflow: hidden;
  /* background-color: transparent; */
  border-radius: 10px;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

.progressBarCss .progress-bar {
  float: left;
  /* width: 70%; */
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: rgb(128, 216, 223, 1);
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
  border-radius: 10px;
}

.progressBarCss {
  margin: 25px 0 0 0;
}

.marginTopExit {
  margin: 15px 0 0 0 !important;
}

.yourListingPanel .panel-body {
  padding: 0;
}

.yourListingPanel {
  border: solid 1px rgba(235, 235, 235, 1);
  height: 185px;
}

.yourListingPanel .panel {
  box-shadow: none;
}

.ql-editor li strong {
  font-weight: bold !important;
}

.homePageGuest .guestPopup {
  top: 60px !important;
  right: inherit !important;
}

.homePageGuest .searchPopoverContent {
  min-height: 100% !important;
}

.homePageGuest .searchFooter {
  position: relative;
}

.homePageGuest {
  background-image: url('assets/chevron-down.svg') !important;
  background-repeat: no-repeat;
  background-position: 96% 10px;
  background-size: 25px 25px !important;
}

.homePageSoloIcon {
  background-image: url('assets/DownArrow.png') !important;
  background-repeat: no-repeat;
  background-position: 96% 50%;
  background-size: 25px 12px !important;
}

.homePageGuestOne {
  background-image: url('assets/DownArrow.png') !important;
  background-repeat: no-repeat;
  background-position: 96% 10px;
  background-size: 25px 12px !important;
}

.clearBoth {
  clear: both;
}

.bookingGuest {
  border-radius: 2px;
  border: 1px solid #dbdbdb;
}

.clearBoth {
  clear: both;
}

.textOverFlow {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* .sliderBg {
	transition: all 100s ease !important;
} */

.CalendarMonthGrid__horizontal {
  width: 100% !important;
  white-space: nowrap;
}

.CalendarMonthGrid {
  background: transparent !important;
}

/*
@media(min-width:1600px) {
	.homeBanner .sliderBg {
		height: 450px;
		min-width: 2000px;
	}
}

@media(min-width:992px) {
	.homeBanner .sliderBg {
		height: 450px;
		min-width: 2000px;
	}
	.homeBanner .sliderBg {
		background-size: 100%!important;
	}

} */

.universityCss .css-bgvzuu-indicatorSeparator {
  display: none !important;
}

.universityCss .css-622gbt-indicatorSeparator {
  display: none !important;
}

.universityCss .css-1wy0on6 span+div {
  display: none;
}

.pressModel .modal {
  top: 32%;
}

.positionStickyMobile {
  position: -webkit-sticky;
  position: sticky;
  top: 87px;
  padding: 20px 8px 10px;
  z-index: 4;
  background: #fff;
  border-top: 1px solid #dce0e0;
  border-bottom: 1px solid #dce0e0;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background-color: transparent;
}

.headerSearch {
  position: absolute;
  top: 12px;
  left: 140px;
}

.cityPageHeader .navbar-form {
  margin-top: 0;
  margin-bottom: 0;
}

.searchPageHeader .navbar-form {
  margin-top: 0;
  margin-bottom: 0;
}

.containerPadding.navbar-default {
  padding-bottom: 0px !important;
  height: auto;
}

.aboutUsHeader {
  height: 87px;
}

@media (max-width: 1199px) and (min-width: 1023px) {
  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-collapse.in {
    display: block !important;
  }

  .ipadMenu .collapse {
    display: none;
  }

  .ipadMenu .collapse.in {
    display: block;
  }

  .ipadMenu .navbar-right {
    float: none !important;
    margin-right: 0;
    /* position: absolute; */
    top: auto;
    left: 0;
    width: 100%;
    background: #fff;
    margin: 0 0 0;
    z-index: 2;
    /* height: 100vh; */
    /* border-top: 1px solid #ccc; */
    padding: 0;
  }

  .ipadMenu .navbar-nav>li {
    float: none;
  }

  .rentAllHeader.navbar-default .navbar-nav>li>a,
  .rentAllHeader.navbar-default .navbar-nav>li>form>button {
    border-bottom: none;
  }

  .navbar-header {
    float: none;
    margin-bottom: 0px;
  }

  .navbar-default .navbar-toggle {
    background-color: transparent;
    position: absolute;
    top: 33px;
    right: 0;
  }

  .navbar-default .navbar-toggle:focus,
  .navbar-default .navbar-toggle:hover {
    background-color: transparent;
  }

  .aboutUsHeader {
    width: 100%;
  }

  .searchPageHeader .navbar-nav>li {
    float: none;
  }

  .searchPageHeader .navbar-right {
    float: none !important;
    background: #fff;
    height: 100vh;
  }

  .searchPageHeader .navbar-left {
    float: none !important;
  }

  .containerPadding .navbar-left {
    float: none !important;
  }

  .containerPadding .navbar-form {
    margin-bottom: 0 !important;
  }

  .containerPadding .navbar-default .navbar-nav {
    border-bottom: 1px solid #e4e4e4;
  }

  .ipadLogout {
    border-bottom: none;
  }

  .cityPageHeader .container-fluid {
    padding: 0 !important;
  }

  .searchPageHeader .container-fluid {
    padding: 0 !important;
  }

  .rentAllHeader.navbar-default .navbar-nav>li>a,
  .rentAllHeader.navbar-default .navbar-nav>li>form>button {
    padding: 0px 15px;
    width: 100%;
    text-align: left;
    height: auto;
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .yourListingPanel {
    height: auto;
    padding: 0 0 100px;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-collapse.in {
    display: block !important;
  }

  .ipadMenu .collapse {
    display: none;
  }

  .ipadMenu .collapse.in {
    display: block;
  }

  .ipadMenu .navbar-right {
    float: none !important;
    margin-right: 0;
    position: relative;
    top: auto;
    left: 0;
    width: 100%;
    background: #fff;
    margin: 0 0 0;
    z-index: 2;
    /* height: 100vh; */
    /* border-top: 1px solid #ccc; */
    padding: 0;
  }

  .viewListingHeader .ipadMenu .navbar-right {
    margin: -1px 0 0;
  }

  .ipadMenu .navbar-nav>li {
    float: none;
  }

  .rentAllHeader.navbar-default .navbar-nav>li>a,
  .rentAllHeader.navbar-default .navbar-nav>li>form>button {
    border-bottom: none;
  }

  .navbar-header {
    float: none;
    margin-bottom: 0px;
  }

  .navbar-default .navbar-toggle {
    background-color: transparent;
    position: absolute;
    top: 33px;
    right: 0;
  }

  .navbar-default .navbar-toggle:focus,
  .navbar-default .navbar-toggle:hover {
    background-color: transparent;
  }

  .ipadMenuContainer .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }

  .searchPageHeader .navbar-nav>li {
    float: none;
  }

  .searchPageHeader .navbar-right {
    float: none !important;
    background: #fff;
    height: 100vh;
  }

  .searchPageHeader .navbar-left {
    float: none !important;
  }

  .containerPadding .navbar-left {
    float: none !important;
  }

  .containerPadding .navbar-form {
    margin-bottom: 0 !important;
  }

  .containerPadding .navbar-default .navbar-nav {
    border-bottom: 1px solid #e4e4e4;
  }

  .ipadLogout {
    border-bottom: 1px solid #e4e4e4;
  }

  .cityPageHeader .container-fluid {
    padding: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .rentAllHeader .navbar-toggle {
    width: 100%;
    padding: 4px 0 0 0;
    margin-right: -10px;
  }
}

@media screen and (max-width: 1199px) {
  .homeMargin {
    padding: 0 !important;
  }

  .rentAllHeader.navbar-default .navbar-toggle:focus,
  .rentAllHeader.navbar-default .navbar-toggle:hover {
    background: transparent;
  }

  .invitePhone ul.country-list {
    max-height: 120px !important;
    width: 100.9% !important;
  }

  .Exploreheader .navbar-collapse {
    margin-left: -30px;
    padding-left: 15px;
  }

  .rentAllHeader.navbar-default .navbar-nav {
    border-bottom: 1px solid #e4e4e4;
  }

  .rentAllHeader .navbar-right {
    padding: 0 0 0 0;
  }

  .rentAllHeader .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border-color: inherit;
    background: #fff;
    margin-top: 0;
    margin-bottom: -3px;
  }

  .rentAllHeader .navbar-collapse {
    overflow-y: auto !important;
    margin-top: 0px;
    position: absolute;
    width: calc(100% + 30px);
    background: #fff;
  }

  .navbar-collapse.collapse.in {
    height: 95vh !important;
    position: fixed;
    padding-bottom: 100px;
    -webkit-overflow-scrolling: touch;
  }

  .menu-open {
    overflow: hidden;
    position: relative;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  .rentAllHeader .navbar-nav {
    margin: 2.5px -15px;
  }

  .rentAllHeader.navbar-default .navbar-nav>li>a,
  .rentAllHeader.navbar-default .navbar-nav>li>form>button {
    font-size: 16px;
    /* border-top: 1px solid #dce0e0; */
    width: 100%;
    text-align: left;
  }

  .rentAllHeader.navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border-color: transparent !important;
  }

  .rentAllHeader.navbar-default .navbar-nav>li:first-child>a,
  .rentAllHeader.navbar-default .navbar-nav>li:first-child>form>button {
    border-top: 0px;
    height: 67px;
    margin-top: 0;
  }

  .rentAllHeader.navbar-default .navbar-nav>li>a:focus,
  .navbar-default .navbar-nav>li>a:hover,
  .rentAllHeader.navbar-default .navbar-nav>li>form>button:focus,
  .navbar-default .navbar-nav>li>form>button:hover {
    color: #77d1da;
  }

  .ListHeader.navbar-default .navbar-toggle:focus,
  .ListHeader.navbar-default .navbar-toggle:hover {
    background: #fff;
  }

  .rentAllHeader .navbar-toggle>span {
    display: flex;
    justify-content: inherit;
    align-items: center;
  }

  .rentAllHeader .navbar-toggle>span a>img {
    margin-top: -13px !important;
    width: 70px !important;
    height: 40px !important;
  }

  .rentAllHeader .navbar-fixed-bottom .navbar-collapse,
  .navbar-fixed-top .navbar-collapse {
    max-height: 100vh !important;
  }

  .sliderCity .slick-next {
    right: 15px !important;
  }

  .sliderCity .slick-prev {
    left: 15px !important;
  }

  .sliderCity .slick-next,
  .sliderCity .slick-prev {
    top: 28% !important;
  }

  .searchPageHeader {
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 640px) {
  .connectionPanel .panel-body {
    padding: 0 15px 15px;
  }

  .mobileHeight {
    padding: 9px 0 10px !important;
    font-size: 15px !important;
  }

  .yourListingPanel {
    padding: 0 0 50px;
    margin-bottom: 40px;
  }

  .containerNopadding .container {
    padding: 0;
  }

  .textOverFlow {
    width: 250px;
  }

  .universityWidth input {
    width: 100% !important;
  }
}

.slick-next.slick-disabled,
.slick-prev.slick-disabled {
  display: none !important;
}

.slick-next:before {
  content: '';
}

.slick-prev:before {
  content: '';
}

.innerControl .slick-next {
  right: 10px;
}

.innerControl .slick-prev {
  left: 10px;
}

/**Price Range rc-slider Start**/

.rc-slider {
  margin-bottom: 12px;
  overflow: visible;
  height: 24px !important;
}

.rc-slider-rail {
  background-color: #dce0e0 !important;
  height: 2px !important;
  position: absolute;
  top: 14px !important;
  width: 100% !important;
}

.rc-slider-step {
  display: none;
}

.rc-slider-handle {
  border: 1px solid #77d1da !important;
  background: #fff !important;
  border-radius: 100px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  cursor: pointer !important;
  height: 24px !important;
  margin-left: -12px !important;
  margin-top: -5px !important;
  position: absolute !important;
  z-index: 1 !important;
  width: 24px !important;
  font-size: 0 !important;
}

.rc-slider-track {
  background-color: #77d1da !important;
  height: 4px !important;
  position: absolute !important;
  top: 13px !important;
}

/**Price Range rc-slider Start**/

/** switch - Start **/

.searchSwitch input:checked+.toggle {
  background-color: #77d1da;
}

.searchSwitch input:checked+.toggle:after {
  background-color: #77d1da;
  margin-left: 24px;
}

/** switch - End **/

/** Carousal - Start **/

.carousel-control {
  text-shadow: none;
  font-size: 48px;
}

.carousel-control.left {
  background-image: none;
}

.carousel-control.right {
  background-image: none;
}

.carousel-control svg {
  position: absolute;
  top: 40%;
  z-index: 5;
  display: inline-block;
}

/* .sliderCity {
	margin: 0 0 0 -14px;
} */

.sliderCity .slick-dots,
.slick-next,
.slick-prev {
  display: block !important;
}

.sliderCity .slick-next {
  font-size: 0;
  line-height: 0;
  top: 25%;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 1px 1px 8px 1px #ccc;
  z-index: 3;
}

.sliderCity .slick-prev {
  font-size: 0;
  line-height: 0;
  top: 26% !important;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 1px 1px 8px 1px #ccc;
  z-index: 3;
}

@media screen and (max-width: 1030px) {
  .sliderCity .slick-next {
    font-size: 0;
    line-height: 0;
    top: 25%;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 1px 1px 8px 1px #ccc;
    z-index: 3;
  }

  .sliderCity .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 25%;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 1px 1px 8px 1px #ccc;
    z-index: 3;
  }
}

.sliderCity .slick-prev:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 12px;
  background: url('assets/left-arrow.png') no-repeat;
  width: 16px;
  height: 16px;
}

.sliderCity .slick-next:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 12px;
  background: url('assets/right-arrow.png') no-repeat;
  width: 16px;
  height: 16px;
}

.sliderCity .slick-prev {
  left: -9px;
}

.sliderCity .slick-next {
  right: -5px;
}

.sliderCity .slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  background: #fff;
}

.teamSlider .slick-next {
  background: url('assets/rightarrow.png');
  background-repeat: no-repeat;
  right: 10px;
  background-size: 70% !important;
  top: -65px;
  width: 30px;
  height: 55px;
}

.teamSlider .slick-prev {
  background: url('assets/rightarrow.png');
  background-repeat: no-repeat;
  right: 10px;
  background-size: 70% !important;
  top: -104px !important;
  width: 30px;
  height: 55px;
  right: 15px;
  left: auto;
  transform: rotate(180deg);
}

.mapInfoWindow .carousel-control svg {
  top: 36%;
}

.carousel-control.left svg {
  left: 5px;
}

.carousel-control.right svg {
  right: 5px;
}

/** Carousal - End **/

/** DateRange - Start **/

.DateRangePicker__picker {
  z-index: 3 !important;
}

.homeDate .DateRangePickerInput {
  border: solid 1px #fff;
  border-radius: 4px;
}

.homeDate .DateInput {
  line-height: inherit;
  font-size: 14px;
  padding: 8px 12px;
}

.homeDate .DateInput .DateInput_1 {
  line-height: inherit;
  font-size: 14px;
  padding: 8px 12px;
}

.homeDate .DateInput_input {
  font-size: 14px !important;
  padding: 2px 0px !important;
}

.DateInput__display-text {
  text-transform: capitalize;
}

.homeDate .DateInput:first-child .DateInput__display-text {
  padding-left: 0px;
}

.homeDate .DateInput__display-text--focused {
  border-bottom: 1px;
  background: none;
  color: #767676;
  box-shadow: 0 1px 0 #77d1da;
  border-radius: 0px;
}

.DateRangePicker,
.DateRangePickerInput {
  width: 100%;
}

.searchDate .DateInput_input {
  font-size: 14px !important;
  padding: 2px 9px;
}

.searchDate .DateInput {
  width: auto;
  line-height: inherit;
  font-size: inherit;
  padding: 5px 0px;
}

.searchDate .DateInput__display-text--focused {
  border-bottom: 1px;
  background: none;
  color: #767676;
}

.searchDate .DateRangePickerInput {
  border-radius: 2px;
  border: 1px solid #dbdbdb;
}

.ContactHost .DateInput {
  line-height: inherit;
  font-size: inherit;
  padding: 0px 0px;
}

.ContactHost .DateInput__display-text--focused {
  border-bottom: 1px;
  background: none;
  color: #767676;
}

.ContactHost .DateRangePickerInput {
  border: 1px solid #dbdbdb;
}

.viewListingDate .DateInput_input {
  font-size: 16px;
  padding: 0px 0px;
  height: 40px;
  color: #484848;
}

.viewListingDate .DateInput {
  font-size: 16px;
  padding: 0px 14px;
  height: 40px;
  color: #484848;
}

.viewListingDate .DateRangePickerInput {
  border-radius: 2px;
  border: 1px solid #dbdbdb;
}

.viewListingDate .DateInput__display-text--focused {
  border-bottom: 1px;
  background: none;
  color: #767676;
}

.buttonDisabled .btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  opacity: 0.9;
}

.becomeForm select {
  background-position: 96% 12px;
  background-size: 22px 22px !important;
}

.addphoneNumber {
  border: solid 1px !important;
  background-color: transparent !important;
  color: #4ad5dc !important;
  padding: 10px 80px;
  border-radius: 5px;
  font-weight: bold;
  margin: 0 0 0 10px;
  text-decoration: none;
}

.tableWidth .tableBoxWidth {
  width: 50%;
  box-shadow: 0px 0px 2.5px 1px #ccc;
  margin: 0 0 0 16px;
}

.tableWidth .btnPrimaryMargin {
  border-color: #4ad5dc;
  color: #4ad5dc;
  background-color: #fff;
  margin: 0 0 0 15px;
}

.tableBoxWidth .headerWidth {
  width: 42%;
}

.geosuggest {
  outline: none;
  width: 100% !important;
  margin: 0px !important;
}

@media screen and (max-width: 768px) {
  .homeDate .DateInput {
    width: auto;
  }

  .addphoneNumber {
    padding: 10px 37px;
  }

  .tableWidth .tableBoxWidth {
    width: 100%;
    margin: 0;
  }

  .tableWidth {
    margin: 0 16px;
  }

  .DayPickerNavigation__verticalDefault {
    bottom: 25px !important;
  }
}

@media screen and (max-width: 767px) {
  .sliderCity .slick-list {
    padding: 0px 50px 0 0 !important;
  }

  .headerSearch {
    width: 60%;
    display: inline-block;
    margin: 0px 0 0 0;
    position: absolute;
    top: 13px;
    right: 17px;
  }

  .addphoneNumber {
    margin: 0 0 0 0px;
    text-decoration: none;
    width: 100%;
    display: block;
    text-align: center;
  }

  .tableBoxWidth .headerWidth {
    width: 100%;
  }

  .tableWidth .tableBoxWidth {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .viewListingDate .DateInput {
    max-width: 45%;
    width: 100%;
    padding: 0px 10px;
  }

  .searchDate .DateInput__display-text {
    /*padding: 4px 38px;*/
  }

  .searchDate .DateRangePickerInput {
    margin-bottom: 12px;
  }

  .noUnderLine {
    margin: 66px 0 0;
    width: 100%;
    padding: 0 14px;
  }

  .contactModel .modal-content {
    height: auto;
  }

  .contactModel .modal-body {
    margin: 0;
    padding: 0;
  }

  .contactModel .paddingMobile {
    padding: 5px 20px 0 !important;
  }

  .contactModel .DateRangePicker__picker {
    top: 45px;
  }

  .contactModel .DateInput--with-caret::after {
    top: 41px;
  }
}

@media screen and (max-width: 480px) {
  .viewListingDate .DateInput {
    max-width: 45%;
    width: 100%;
    padding: 0px 5px;
    font-size: 14px;
  }

  .totBill .col-xs-6 {
    width: 100%;
    text-align: center;
  }

  .ContactHost .DateInput {
    width: auto;
  }

  .titleText .searchDate .DateInput__display-text {
    /*padding: 4px 38px;*/
  }

  .homeDate .DateInput {
    width: 115px;
  }
}

/** DateRange - End **/

/** GeoSuggest -Start - Mar 25, 2017**/

.geosuggest {
  outline: none;
  width: 100% !important;
  margin: 0px !important;
}

.geosuggest__suggests {
  width: 100.1% !important;
  left: -17px !important;
}

.overBordr .geosuggest__suggests {
  width: 100% !important;
  left: 0px !important;
}

.homeSearchForm .geosuggest__input {
  border: 0px !important;
  box-shadow: none !important;
  padding: 8px 12px !important;
  outline: none !important;
  color: #484848 !important;
  border-radius: 4px;
}

.homeSearchForm .geosuggest__suggests {
  border: 1px solid #e4e4e4 !important;
  border-top-width: 2px !important;
  left: -17px !important;
  right: -16px !important;
  margin: 0px 17px 0;
  z-index: 99999;
}

.SearchPageForm .geosuggest__input,
.SearchPageForm .geosuggest__input:focus,
.SearchPageForm .geosuggest__input:hover {
  border: 1px solid #e4e4e4;
  box-shadow: none !important;
  padding: 10px !important;
  outline: none !important;
  color: #484848 !important;
  -webkit-appearance: none;
}

.SearchPageForm .geosuggest__suggests {
  border: 1px solid #e4e4e4 !important;
  border-top-width: 0px !important;
  left: 0px !important;
  right: 0px !important;
  margin-top: 0px !important;
}

.homeSearchForm .geosuggest__suggests--hidden,
.SearchPageForm .geosuggest__suggests--hidden {
  border-width: 0px !important;
}

.geosuggest__item {
  font-size: 14px !important;
}

.geosuggest__item:hover,
.geosuggest__item:focus,
.geosuggest__item--active {
  background: #4ad5dc !important;
  color: #fff !important;
}

.SearchPageForm .panel-body {
  padding: 0px;
}

/** GeoSuggest -End**/

/** Google map - Start **/

.gm-style-iw {
  /* max-width: 260px !important;
top: 12px !important;
left: 0px !important;
right: 0px !important; */
  background-color: #fff;
  box-shadow: none;
  border: 0px;
  border-radius: 0px;
  margin: 0 auto;
  padding: 18px 0 0 0;
}

.gm-style-iw div {
  overflow: hidden;
}

.gm-style div {
  /*max-width: 261px;*/
}

.gm-style-iw+div {
  display: none;
}

.homeSearchForm select {
  background-position: 96% 12px !important;
}

.homeDate .DateInput--with-caret::before {
  top: 47px;
}

.homeDate .DateInput--with-caret::after {
  top: 47px;
}

.homeDate .DateRangePicker__picker {
  top: 60px;
}

.phoneNumber {
  margin: 0 auto !important;
}

.addNumberTrust {
  padding: 0 0 0 15px !important;
}

@media screen and (min-width: 321px) {
  .gm-style-iw {
    /* width: 260px !important; */
  }
}

@media screen and (max-width: 321px) {
  .gm-style-iw {
    /* right: 0 !important; */
    padding: 18px 0 0 20px;
  }
}

/** Google map - End **/

/** Message Panel Start **/

.messageTextArea .panel-body {
  padding: 0px;
}

.dashboardMessage .panel-body {
  padding: 0px;
}

/** Message Panel Start **/

/** Panel Footer Start **/

.panel-footer {
  text-align: right;
  background-color: #ffffff;
  padding: 15px;
}

/** Panel Footer End **/

.guestpanel .panel-body {
  padding: 0px;
}

.ContactHost .modal-dialog {
  max-width: 650px;
}

.loginModal .modal-content {
  border-radius: 0px;
}

@media screen and (max-width: 640px) {
  .ContactHost .modal-dialog {
    margin: 0px;
  }

  .sliderCity .slick-next,
  .slick-prev {
    top: 24%;
  }

  .sliderCity {
    margin: 0 0 0 0;
  }
}

/**Signup Modal **/

@media screen and (max-width: 640px) {
  .signupModal .modal-content {
    border-radius: 0px;
  }

  .loginModal .modal-content {
    border-radius: 0px;
  }
}

@media screen and (max-width: 767px) {
  .searchDate .DateRangePickerInput {
    margin-bottom: 12px;
  }
}

/**Signup Modal **/

/**Feature Alert Modal **/

.featureAlert .modal-content {
  border-radius: 0px;
}

/** Transaction Panel **/

.transactionPanel .panel-heading {
  padding: 0;
}

.reactable-filter-input {
  height: 35px;
  max-width: 250px;
  width: 100%;
  padding: 9px 10px;
  border: 1px solid #ccc;
}

.reactable-page-button {
  padding-right: 10px;
  font-size: 15px;
}

.reactable-next-page {
  font-size: 15px;
}

.reactable-previous-page {
  font-size: 15px;
  padding-right: 10px;
}

/* List Photos */

.dzInputContainer {
  position: relative;
}

.listPhotoContainer .dzInputContainer .dz-hidden-input {
  height: 100% !important;
  width: 100% !important;
  visibility: visible !important;
  opacity: 0 !important;
  cursor: pointer;
}

/* View Listing */

.sticky .bookItContentCommon {
  margin-top: 0px !important;
}

.table-responsive>.table {
  margin-bottom: 0;
}

.table-responsive {
  width: 100%;
  margin-bottom: 15px;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border: 1px solid #ddd;
}

body.modal-open {
  /* position: fixed; */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Auto suggest */

.searchCategory .react-autosuggest__input {
  border: none;
  outline: none;
  padding: 8px 0px;
  font-size: 16px;
  width: 100%;
  height: 40px;
}

.react-autosuggest__container {
  position: relative;
  width: 100%;
  height: 100%;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0px;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
  z-index: 5;
}

.react-autosuggest__suggestions-container--open {
  border-bottom: 1px solid #aaa;
}

.homeSearchForm .react-autosuggest__suggestions-container {
  left: -1px !important;
  width: calc(100% + 2px) !important;
}

.react-autosuggest__suggestions-list {
  position: relative;
  list-style-type: none;
  margin: 0px;
  width: 100%;
  border-top-width: 0px;
  width: 100%;
  padding: 0px;
  z-index: 5;
}

.react-autosuggest__suggestions-list li {
  font-size: 14px !important;
  padding: 0.5em 0.65em;
  cursor: pointer;
}

.react-autosuggest__suggestions-list li:hover,
.react-autosuggest__suggestions-list li:focus,
.react-autosuggest__suggestions-list li.react-autosuggest__suggestion--highlighted {
  background: #49d5dc !important;
  color: #fff !important;
}

.react-autosuggest__input,
.react-autosuggest__input:hover,
.react-autosuggest__input:focus {
  height: 100% !important;
  border-radius: 2px;
  font-size: 18px;
  /* border: 1px solid #e4e4e4 !important; */
  border-width: 0px;
  box-shadow: none !important;
  padding: 20px !important;
  outline: none !important;
  color: #767676 !important;
  width: 100%;
}

.react-autosuggest__container--open .react-autosuggest__input,
.react-autosuggest__container--open .react-autosuggest__input:hover,
.react-autosuggest__container--open .react-autosuggest__input:focus {
  border-bottom: 0px !important;
}

/* Footer Toggle */

@-webkit-keyframes fadeInUpCustom {
  from {
    opacity: 1 !important;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1 !important;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpCustom {
  from {
    opacity: 1 !important;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1 !important;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeOutDownCustom {
  from {
    opacity: 1 !important;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 1 !important;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDownCustom {
  from {
    opacity: 1 !important;
  }

  to {
    opacity: 1 !important;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.react-drawer-drawer[class*='fadeInUp'] {
  -webkit-animation-name: fadeInUpCustom !important;
  animation-name: fadeInUpCustom !important;
  z-index: 7;
}

.react-drawer-drawer[class*='fadeIfadeOutDownnUp'] {
  -webkit-animation-name: fadeOutDownCustom !important;
  animation-name: fadeOutDownCustom !important;
}

.react-drawer-drawer {
  background-color: #ffffff !important;
  height: auto !important;
  opacity: 1 !important;
  -webkit-animation-duration: 0.2s !important;
  animation-duration: 0.2s !important;
}

/* become a member global */

.sticky_box.affix {
  position: static;
}

.sticky_box {
  background-color: #fff;
  transition: margin 0.4s linear;
  margin-top: -480px;
}

@media (min-width: 1201px) {
  .sticky_box.affix {
    background-color: #fff;
    position: fixed;
    margin-top: 0px;
    top: 30px;
    z-index: 1050;
  }
}

@media (max-width: 1200px) {
  .sticky_box {
    margin-top: -400px;
  }

  .padlft {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.creditpage .form-control {
  border-radius: 0px;
  padding: 21px !important;
  border-right: 0;
  font-size: 17px;
}

.creditpage .panel .panel-body {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

@media screen and (max-width: 640px) {
  .creditpage .form-control {
    border: 1px solid #ccc !important;
  }

  .creditpage .inputBorderstyle {
    border-style: dashed !important;
  }
}

.headerSearch .geosuggest__input-wrapper {
  line-height: 1.42857143;
}

.headerSearch .geosuggest__input,
.headerSearch .geosuggest__input:focus,
.headerSearch .geosuggest__input:hover {
  border: 0px;
  box-shadow: none !important;
  padding: 8px 10px 8px 5px !important;
  outline: none !important;
  color: #767676 !important;
  height: 44px;
  font-size: 14px !important;
  border-radius: 4px;
}

.headerSearch .geosuggest__suggests {
  border: 1px solid #e4e4e4;
  border-top-width: 0px !important;
  left: -45px !important;
  right: 0px !important;
  margin-top: 3px !important;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.geosuggest__item {
  font-size: 14px !important;
  color: #484848;
}

@media (max-width: 1199px) and (min-width: 1024px) {
  .headerSearch {
    width: 50%;
    position: absolute;
    top: 20px;
    left: 127px;
  }

  .rentAllHeader.navbar-default .navbar-nav>li>a,
  .rentAllHeader.navbar-default .navbar-nav>li>form>button {
    padding: 0px 20px;
    width: 100%;
    text-align: left;
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .headerSearch {
    width: 30%;
    position: absolute;
    top: 20px;
    left: 150px;
  }

  .sliderCity .slick-next .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 28%;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 1px 1px 8px 1px #ccc;
    z-index: 3;
  }
}

@media screen and (max-width: 360px) {
  .gm-style-iw {
    /* width: 175px !important; */
  }
}

@media screen and (max-width: 320px) {

  .headerSearch .geosuggest__input,
  .headerSearch .geosuggest__input:focus,
  .headerSearch .geosuggest__input:hover {
    font-size: 13px !important;
  }

  .rentAllHeader .navbar-toggle>span a>img {
    width: 60px !important;
    height: 35px !important;
  }

  .navbar {
    margin-bottom: 0;
  }
}

.bookModal .modal-content {
  height: auto;
}

.listplaceTooltip,
.tooltip-inner {
  max-width: 276px;
  padding: 20px;
  color: #484848;
  text-align: left;
  background-color: #ffffff;
  border-radius: 0.25rem;
  font-size: 14px !important;
  border: 1px solid #e0d9d9;
}

.listplaceTooltip,
.tooltip.left .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-color: transparent !important;
}

.listplaceTooltip,
.tooltip.in {
  filter: alpha(opacity=90);
  opacity: 1 !important;
}

.sliderNew .slick-next {
  right: 2px !important;
}

.sliderNew .slick-prev {
  left: 2px !important;
}

.sliderNew .slick-next {
  font-size: 0;
  line-height: 0;
  top: 22% !important;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 1px 1px 8px 1px #ccc;
  z-index: 3;
}

.sliderNew .slick-prev {
  font-size: 0;
  line-height: 0;
  top: 22% !important;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 1px 1px 8px 1px #ccc;
  z-index: 3;
}

.sliderNew .slick-prev:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 12px;
  background: url('assets/left-arrow.png') no-repeat;
  width: 16px;
  height: 16px;
}

.sliderNew .slick-next:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 12px;
  background: url('assets/right-arrow.png') no-repeat;
  width: 16px;
  height: 16px;
}

@media screen and (max-width: 1030px) {
  .sliderNew .slick-next {
    font-size: 0;
    line-height: 0;
    top: 20% !important;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 1px 1px 8px 1px #ccc;
    z-index: 3;
  }

  .sliderNew .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 20% !important;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 1px 1px 8px 1px #ccc;
    z-index: 3;
  }
}

@media screen and (max-width: 600px) {
  .viewProfileSlider .sliderNew .slick-next {
    font-size: 0;
    line-height: 0;
    top: 34% !important;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 1px 1px 8px 1px #ccc;
    z-index: 3;
  }

  .viewProfileSlider .sliderNew .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 34% !important;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 1px 1px 8px 1px #ccc;
    z-index: 3;
  }
}

.redux-toastr .toastr.success {
  background-color: #49d5dc !important;
}

.redux-toastr .toastr.rrt-success {
  background-color: #49d5dc !important;
}

@media screen and (max-width: 767px) {
  .creditpage .form-control {
    border-right: 1px solid #ccc;
  }
}

.BecomeCalendar .DayPicker-wrapper {
  padding: 0;
  margin: 20px 0 0;
}

.BecomeCalendar .DayPicker-Caption {
  width: 250px;
  height: 50px;
  font-size: 18px;
  margin: 0 0 20px auto;
  position: relative;
  right: 110px;
}

.BecomeCalendar .DayPicker-Caption div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

@-moz-document url-prefix() {
  .BecomeCalendar .DayPicker-Caption div {
    left: 12px;
  }
}

.BecomeCalendar .DayPicker-NavBar {
  width: 250px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 0 0 0;
  font-size: 18px;
  margin: 0 0 20px auto;
  position: absolute;
  top: 0;
  right: 110px;
  z-index: 9;
}

.BecomeCalendar .DayPicker-Footer {
  position: absolute;
  top: 0;
  right: 0;
  display: inherit;
}

.BecomeCalendar .DayPicker-TodayButton {
  color: #4ad5dc;
  width: 100px;
  height: 50px;
  border: 1px solid #4ad5dc;
  border-radius: 5px;
}

.BecomeCalendar .DayPicker-NavBar .DayPicker-NavButton {
  transform: translate(0%, -50%);
  top: 50%;
}

.BecomeCalendar .DayPicker-WeekdaysRow .DayPicker-Weekday {
  border: 1px solid #ccc;
  height: 100px;
  position: relative;
}

.BecomeCalendar .DayPicker-WeekdaysRow .DayPicker-Weekday abbr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .BecomeCalendar .DayPicker-NavBar {
    width: 100%;
    margin: 0;
    top: 0;
    right: 0;
    position: relative;
  }

  .BecomeCalendar .DayPicker-Caption {
    width: 100%;
    right: 0;
    top: -50px;
    font-size: 14px;
  }

  @-moz-document url-prefix() {
    .BecomeCalendar .DayPicker-Caption div {
      left: 50%;
      top: -25px;
    }
  }

  .BecomeCalendar .DayPicker-Footer {
    top: 60px;
    width: 100%;
  }

  .BecomeCalendar .DayPicker-TodayButton {
    width: 100%;
    font-size: 14px;
  }

  .BecomeCalendar .DayPicker-WeekdaysRow .DayPicker-Weekday {
    height: 36px;
  }

  /* .DayPicker-Day .priceAlignment {
display: none;
} */
  .DayPicker-Day .priceAlignment {
    bottom: -1px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .BecomeCalendar .DayPicker-Day {
    height: 50px;
    padding: 10px;
    font-size: 14px;
  }

  .DayPicker-Day .priceAlignment {
    bottom: 0px;
    font-size: 12px;
  }

  .BecomeCalendar .DayPicker-WeekdaysRow .DayPicker-Weekday {
    height: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .becomeHostDatePicker .DateInput {
    width: 45%;
    font-size: 14px;
  }
}

.newProperty {
  display: block;
  width: 140px;
}

.css-1e5renz-container {
  cursor: not-allowed !important;
  pointer-events: unset !important;
}

.css-jtvqkj-control {
  cursor: not-allowed !important;
}

.css-jtvqkj-control input {
  opacity: 0 !important;
  cursor: not-allowed !important;
}

.css-ue83k8-singleValue {
  color: white !important;
}

.aboutSlider .slick-prev {
  left: 0px !important;
  z-index: 9999;
  height: 100%;
  width: 50px !important;
}

.aboutSlider .slick-next {
  right: 0px !important;
  z-index: 9999;
  height: 100%;
  width: 50px !important;
}

.aboutSlider .slick-prev:hover {
  background-color: black !important;
  background-image: url('assets/leftarrow.png') !important;
  background-size: 50px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  opacity: 0.5 !important;
}

.aboutSlider .slick-next:hover {
  background-color: black !important;
  background-image: url('assets/rightarrow.png') !important;
  background-size: 50px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  opacity: 0.5 !important;
}

.homeDate .DateInput {
  width: 45%;
}

.homeDate .DateRangePicker__picker {
  z-index: 5 !important;
}

@media screen and (max-width: 1024px) {
  .homePageGuest .searchFooter {
    position: relative;
  }
}

.searchDate .DateInput {
  width: 45%;
}

.searchDate .DateInput--with-caret::before {
  top: 39px !important;
}

.searchDate .DateInput--with-caret::after {
  top: 40px !important;
}

.searchDate .DateRangePicker__picker {
  top: 50px !important;
}

.searchDate .DateRangePicker__picker {
  z-index: 4 !important;
}

.searchDate .DateInput--with-caret::before,
.DateInput--with-caret::after {
  z-index: 4 !important;
}

.gm-style .gm-style-iw-a {
  position: absolute;
  left: 0px;
  top: 115px;
}

.ContactHost .DateInput {
  width: 45%;
}

.ContactHost .DateInput--with-caret::before {
  top: 41px !important;
}

.ContactHost .DateInput--with-caret::after {
  top: 43px !important;
}

.ContactHost .DateRangePicker__picker {
  top: 53px !important;
}

.ContactHost .DateRangePicker__picker {
  z-index: 1 !important;
}

.viewListingDate .DateInput {
  width: 45%;
}

.viewListingDate .DateInput--with-caret::before {
  top: 42px !important;
}

.viewListingDate .DateInput--with-caret::after {
  top: 43px !important;
}

.viewListingDate .DateRangePicker__picker {
  top: 54px !important;
}

.viewListingDate .DateRangePicker__picker {
  z-index: 1 !important;
}

.becomeAmemberSlider .slick-slider .slick-list,
.slick-slider .slick-track {
  border-radius: 15px !important;
  height: auto;
}

/* .becomeAmemberSlider  .slick-initialized .slick-slide {
    border-radius: 15px;
} */

.becomeAmemberSlider .slick-next,
.slick-prev {
  top: 48% !important;
}

.ContactHost .modal-content {
  min-height: 200px !important;
  max-height: 100vh !important;
  overflow-y: auto !important;
}

.becomeAmemberSlider {
  height: 300px;
}

/* .ReactCrop {
    position: relative;
    display: block !important;
    cursor: auto !important;
    overflow: auto !important;
    max-width: auto !important;
    background-color: #dedede !important;
}

.ReactCrop__image {
    display: block;
    max-width: auto !important;
    max-height: 100% !important;
} */

@media screen and (max-width: 992px) and (max-width: 768px) {
  .searchPageHeader {
    padding-bottom: 9px !important;
  }
}

@media screen and (max-width: 767px) {
  .ReactCrop__image {
    display: block;
    max-width: 266px !important;
    margin: 0 auto !important;
  }
}

.sliderHome .slick-slide {
  height: auto !important;
}

@media (min-width: 769px) {
  .bookModal .modal-content {
    height: 634px !important;
  }
}

/* .mapContainer>div>div>div>div {
	background-color: transparent !important;
} */

@media (max-width: 991px) {
  .responsiveGrid .container {
    padding: 0 !important;
    width: 100% !important;
  }

  .homeMargin {
    margin-bottom: 0px !important;
  }
}

.DateRangePickerInput_arrow>svg {
  display: none;
}

.DateRangePickerInput_arrow {
  background: url('assets/rightarrow.png');
  background-size: 13px;
  width: 10%;
  height: 40px;
  background-repeat: no-repeat;
  background-position: 25% 50%;
}

.topPicks .contentOne {
  color: #232323 !important;
  font-weight: 900;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topPicks .priceSection {
  color: #4ad5dc !important;
}

.priceSection {
  color: #4ad5dc !important;
}

.homeMargin {
  margin-top: 20px;
  margin-bottom: 30px;
}

.DateRangePickerInput__arrow>svg {
  display: none !important;
}

.DateRangePickerInput__arrow {
  background: url('assets/rightarrow.png');
  background-size: 13px;
  width: 10%;
  height: 40px;
  background-repeat: no-repeat;
  background-position: 25% 50%;
}

/* Hamburger Icon */

.menuToggle {
  display: block;
  position: relative;
  top: 0;
  right: 0;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

.menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

.menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #4ad5dc;
  border-radius: 0px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease;
}

/* .menuToggle span{
	 background:#0009 !important;
 } */

.menuToggle span:first-child {
  transform-origin: 0% 0%;
}

.menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

.menuToggle input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-5px, -20px);
  background: #4ad5dc;
}

.menuToggle input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.menuToggle input:checked~span:nth-last-child(2) {
  transform: rotate(-45deg) translate(1px, 15px);
}

.menuToggle input:checked~ul {
  transform: none;
}

/* @media(min-width:768px) and (max-width:991px) {
	.searchformHome .ipadWidth {
		width: 500px;
		padding-right: 15px;
	}
} */

/* Text transition for future use

.content {
	position: relative;
	height: 100%;
	overflow: hidden;
	font-family: 'Lato', sans-serif;
	font-size: 80px;
	line-height: 40px;
	color: #6b7c7e;
	display: flex;
	justify-content: CENTER;
	align-items: center;
}

.content__container {
	font-weight: 600;
	overflow: hidden;
	height: 80px;
	padding-left: 35%;
	display: flex;
	justify-content: center;
}

.content__container__text {
	display: inline;
	float: left;
	margin: 0;
	margin-top: 6px;
}

.content__container__list {
	margin-top: -8px;
	padding-left: 0 !important;
	margin-bottom: 0 !important;
	text-align: left;
	list-style: none;
	-webkit-animation-name: change;
	-webkit-animation-duration: 10s;
	-webkit-animation-iteration-count: infinite;
	animation-name: change;
	animation-duration: 10s;
	animation-iteration-count: infinite;
	color: #4ad5dc;
}

.content__container__list__item {
	line-height: 80px;
	margin: 0;
	margin-bottom: 5px;
}

@-webkit-keyframes opacity {
	0%, 100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}
} */

a .exclusiveIcon {
  color: #000;
}

.exclusiveIcon {
  position: absolute;
  top: 0;
  padding: 5px;
  background: #4ad5dc;
  padding-right: 40px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  background-image: url('assets/whitekey.png');
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: 90%;
}

.exclusiveIcon.exclusiveFont {
  font-weight: 400;
}

.exclusiveIconSearch {
  position: absolute;
  top: 13px;
  padding: 5px;
  background: #77d1da;
  padding-right: 40px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  background-image: url('assets/whitekey.png');
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: 90%;
  color: #333 !important;
}

.exclusiveIconProfile {
  position: absolute;
  top: 0;
  padding: 5px;
  background: #4ad5dc;
  padding-right: 40px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  background-image: url('assets/whitekey.png');
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: 90%;
  color: #000;
}

.supTag sup {
  font-size: 9px !important;
  top: -15px;
  font-weight: 400;
  font-weight: bold;
}

.listingExclusiveIcon {
  position: absolute;
  top: 0;
  padding: 12px;
  background: #4ad5dc;
  padding-right: 40px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  background-image: url('assets/whitekey.png');
  background-repeat: no-repeat;
  background-size: 35px;
  background-position: 92% 43%;
  width: 150px;
  text-align: center;
  color: #000;
}

a .exclusiveIconOther {
  color: #000;
}

.exclusiveIconOther {
  position: absolute;
  top: 20px;
  padding: 5px;
  background: #4ad5dc;
  padding-right: 40px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  background-image: url('assets/whitekey.png');
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: 90%;
}

.BecomeCalendar .DayPicker-Day {
  max-width: 136px;
  overflow: hidden;
}

.BecomeCalendar .priceAlignment {
  max-width: 118px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*
.BecomeCalendar .DayPicker-Day::-webkit-scrollbar {
	width: 10px;
}



.BecomeCalendar .DayPicker-Day::-webkit-scrollbar-track {
	background: transparent!important;
}



.BecomeCalendar .DayPicker-Day::-webkit-scrollbar-thumb {
	background: #888;
}



.BecomeCalendar .DayPicker-Day::-webkit-scrollbar-thumb:hover {
	background: red;
} */

/* width */

.sidebarNav::-webkit-scrollbar {
  width: 10px;
}

/* Track */

.sidebarNav::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */

.sidebarNav::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

.sidebarNav::-webkit-scrollbar-thumb:hover {
  background: red;
}

@media (min-width: 767px) {
  .rentAllHeader .navbar-brand>img {
    max-width: 100px;
    max-height: 65px;
  }

  .adimnHeader .navbar-brand>img {
    max-width: 100px;
    max-height: 65px;
  }
}

.containerPadding .container-fluid {
  padding: 0 !important;
}

.guestPopup {
  right: 0 !important;
}

@media (max-width: 1023px) and (min-width: 768px) {
  .homePageGuestOne .guestPopup {
    max-height: 25vh !important;
    white-space: inherit !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .guesttabLeft .guesttap {
    left: auto;
    right: 0 !important;
  }

  .rentAllHeader .navbar-right {
    margin-right: -2%;
  }

  .sliderCity .slick-next {
    right: 7px;
    top: 20%;
  }

  .sliderCity .slick-prev {
    left: 0px;
    top: 20% !important;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .teamSlider .slick-slide {
    float: none;
    display: block;
  }

  .teamSlider .slick-track {
    width: 100% !important;
  }

  .menuToggle {
    top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .letsBtnSection .letsBtn {
    width: 100%;
  }

  .teamSlider .slick-slide {
    float: none;
    display: inline-block;
    width: 49% !important;
  }

  .teamSlider .slick-track {
    width: 100% !important;
  }

  .teamSlider .slick-slide:first-child {
    margin-right: 2%;
  }

  .teamSlider .slick-slide:nth-child(3) {
    margin-right: 2%;
  }

  .sliderCity .slick-next {
    right: 7px;
    top: 21%;
  }

  .sliderCity .slick-prev {
    left: 7px;
    top: 21% !important;
  }

  .BecomeCalendar .DayPicker-NavBar {
    width: 233px;
  }

  .teamSlider .slick-track {
    transform: none !important;
  }
}

@media (min-width: 1200px) and (max-width: 1280px) {
  .rentAllHeader .navbar-right {
    margin-right: -2%;
  }
}

@media (min-width: 1200px) {
  .rentAllHeader .navbar-right {
    padding: 11px 0 0 0;
    margin-right: -2%;
  }

  /* .AboutUsLayout .aboutUsHeader {
  min-width: 1200px;
  max-width: 1450px;
  width: 100%;
  padding-left: 52px;
  padding-right: 52px;
} */
  .aboutUsHeader {
    min-width: 1200px;
    max-width: 1450px !important;
    width: 100%;
    padding-left: 52px !important;
    padding-right: 52px !important;
  }
}

@media (min-width: 992px) {
  .viewProfileSlider .slick-initialized .slick-slide {
    /* padding-right: 15px; */
    padding: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-right: 0 !important;
  }

  .slick-initialized .slick-slide {
    padding-right: 15px;
    padding: 0 !important;
    padding-right: 14px !important;
  }

  .sliderCity .slick-initialized .slick-slide {
    /* margin-right: -14px; */
    padding-right: 14px !important;
  }

  .sliderCity .slick-initialized .slick-slide .col-md-12 {
    padding: 0 !important;
  }

  .teamSlider .slick-next {
    right: 6px;
  }

  .teamSlider .slick-prev {
    right: 12px;
  }

  .sliderCity .slick-slider {
    margin-right: 0px;
    margin-left: 0px;
  }

  .homeBanner .slick-initialized .slick-slide {
    padding-right: 0px !important;
    margin-right: 0px;
    margin-left: 0px;
  }

  /* .homesliderNo .homeSliders {
  padding-right: 6px;
  padding-left: 9px;
}
.homesliderNo .homeSliders .sliderDesc {
  padding-left: 0px;
}
.homesliderNo .slick-slider {
  margin-left: -8px;
} */
}

/* .displayBlock {
	display: none;
}

td .CalendarDay:active .displayBlock {
	display: block;
}  */

@media (min-width: 768px) {
  .hide-search {
    display: none;
  }

  .circulOne {
    margin-top: 20px !important;
    margin-bottom: 20px;
    max-width: 140px;
    margin: 0 auto;
    text-align: left;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .AboutUsLayout .aboutUsHeader {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.availabilityCalendar .DayPicker_transitionContainer {
  overflow: hidden !important;
}

@media screen and (max-width: 767px) {
  .availabilityCalendar .DayPicker_transitionContainer {
    overflow: hidden !important;
  }
}

.searchDate .DateRangePicker_picker {
  z-index: 4 !important;
}

.saveCalendarModel .modal-body {
  width: 100%;
  height: 100%;
  max-height: 450px;
  min-height: 450px;
  max-width: 500px;
  margin: 0 auto;
}

.saveCalendarModel .modal-dialog {
  max-width: 500px !important;
}

.saveCalendarModel .close {
  font-size: 40px;
  margin-top: 0px !important;
}

.saveCalendarDateRange .DateInput {
  width: 45% !important;
}

.saveCalendarDateRange .SingleDatePicker .DateInput {
  width: 100% !important;
}

.saveCalendarDateRange .DateInput_input {
  font-size: 16px !important;
}

@media screen and (max-width: 767px) {
  .saveCalendarModel .close {
    font-size: 36px;
    margin-top: 0px !important;
  }

  .saveCalendarModel .modal-dialog {
    max-width: 100% !important;
  }

  .BecomeCalendar .priceAlignment {
    max-width: 35px;
    overflow-x: visible;
    text-overflow: inherit;
  }

  .mobileScroll {
    overflow: auto;
  }
}

.personalModel .close {
  margin-top: -3px !important;
  font-size: 30px;
}

.dropdownHost .dropdown-menu {
  right: -105px;
  right: -105px;
}

@media (max-width: 1200px) and (min-width: 768px) {
  .homeBanner .slick-initialized .slick-slide {
    animation: inherit !important;
  }
}

.homeBanner .single-Image .slick-initialized .slick-slide {
  animation: inherit !important;
}

.circulOne .CircularProgressbar .CircularProgressbar-trail {
  stroke: rgb(197, 196, 196);
  stroke-linecap: round;
  stroke-width: 8px;
  transition: stroke-dashoffset 1s ease 0s;
}

.circulOne .CircularProgressbar .CircularProgressbar-path {
  stroke-width: 8px;
  stroke: #77d1da !important;
  transition: stroke-dashoffset 1s ease 0s;
}

.circulOne .CircularProgressbar .CircularProgressbar-text {
  dominant-baseline: middle;
  text-anchor: middle;
  font-size: 25px;
}

.circulOne .CircularProgressbar {
  width: 100%;
  max-width: 74px;
  margin: 0 auto;
  text-align: center;
  display: block;
  margin-bottom: 15px;
  transition: 0.5s linear all;
}

.circulOne {
  margin-top: 20px;
  margin-bottom: 20px;
}

.editProfile .geosuggest__input:focus {
  /* border-color: transparent; */
  /* box-shadow: none; */
  border-color: #66afe9 !important;
  outline: 0 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6) !important;
}

.editProfile .geosuggest__input {
  border: 1px solid #aaa;
}

.editProfile .geosuggest__suggests {
  border: 1px solid #aaa !important;
  border-top: 0px !important;
}

.responsiveGrid {
  background-color: #fff;
  z-index: 10;
  position: relative;
}

:focus {
  outline: none !important;
}

.DateRangePicker_picker__portal {
  z-index: 100 !important;
}

.mapContainer .gmnoprint.gm-bundled-control {
  /* margin-top: 40px !important; */
  /* top: 15px !important; */
  top: 15px !important;
  right: 50px;
  left: auto !important;
  /* right: 50px;
  left: auto !important; */
}

.chkBox .icheckbox_minimal-blue {
  position: relative;
  width: 23px;
  height: 23px;
  bottom: 1px;
}

.slick-initialized .slick-slide:first-child {
  margin-left: 0 !important;
}

.chkBox .icheckbox_minimal-blue {
  position: relative;
  width: 23px;
  height: 23px;
  bottom: 1px;
}

@media screen and (max-width: 767px) {
  .responsiveGrid {
    background-color: #fff;
    z-index: 0;
    position: relative;
  }
}

.NewGoLogo {
  width: 55px;
  display: inline-block;
}

@media (min-width: 992px) {
  .homesliderNo .Nomargin-homeslider {
    margin: 0;
  }
}

/* social share */

.sharesocialModal .modal-content {
  padding: 24px !important;
  border-radius: 5px;
}

.sharesocialModal .modal-header {
  padding: 0px;
  border-bottom: none;
}

.sharingsocial>a {
  border-right: 0px !important;
  border-left: 0px !important;
  padding: 0px 0px !important;
  color: #77d1da !important;
  font-size: 16px !important;
  font-weight: 300 !important;
}

.sharingsocial>a:hover {
  background: transparent !important;
}

.sharingsocial>a:focus {
  background: transparent !important;
}

.copyToClipboard {
  padding: 24px 0px;
  display: block;
}

.SocialMediaShareButton {
  outline: 0 !important;
  padding: 24px 0px;
}

.customClosebutton>button {
  padding: 0px;
  text-align: center;
  height: 35px;
  width: 35px;
  background-color: transparent;
  border-radius: 50%;
  margin-left: 0px !important;
  box-shadow: rgb(216, 216, 216) 0px 0px 4px 2px !important;
}

.customClosebutton>button>span {
  position: absolute;
  top: 23px;
  left: 28px;
}

.closeBtn>button>span {
  padding: 2px 5px 2px 5px !important;
}

body {
  max-width: 2000px;
  display: block;
  margin: 0 auto !important;
}

.sharesocialModal .modal-header .close {
  float: left;
}

.sharesocialModal .customClosebutton>button>span {
  position: absolute;
  top: 28px;
  left: 30px;
}

.modal-footer .btn-danger {
  border-color: #77d1da !important;
  background-color: #77d1da !important;
  color: #fff;
}

.modal-footer .btn-danger:hover {
  border-color: #49d5dc !important;
  background-color: #49d5dc !important;
  color: #fff;
}

.activationStepPanelNew {
  max-width: 530px;
  margin: 0 auto;
  height: 100%;
  min-height: 520px;
  text-align: center;
  border: none;
}

.activationStepPanelSectionNew {
  padding-top: 15px;
}

.buttonNew {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 10px 16px;
  width: 100%;
  outline: 0;
  border: 1px solid #ffffff;
  border-radius: 4px;
  background: #77d1da;
  color: #484848;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.3333333;
  cursor: pointer;
}

.btnPrimaryNew,
.btnPrimaryNew:hover,
.btnPrimaryNew:active {
  border-color: #77d1da !important;
  color: #77d1da !important;
  background-color: #fff !important;
}

.btnWidthNew {
  width: 100%;
  max-width: 200px;
}

.btnLarge {
  padding: 9px 27px;
}

.btnNext,
.btnNext:hover,
.btnNext:focus,
.btnNext:active {
  border-color: #77d1da !important;
  background-color: #77d1da !important;
  color: #fff !important;
}

.pullRight {
  float: right;
}

.noPadding {
  padding: 0;
}

.imgBorder {
  border-top: 1px solid #dedede;
  padding-top: 24px;
}

.padlft {
  padding-left: 0px;
}

@media screen and (max-width: 767px) {
  .btnWidthNew {
    width: 100%;
    max-width: 100px;
  }

  .gm-style .gm-style-iw-c {
    padding: 12px !important;
    padding-top: 24px !important;
  }

  .gm-ui-hover-effect {
    top: -4px !important;
    right: -5px !important;
  }

  .mapContainer .reviewSectio {
    margin-left: 0px !important;
  }

  .mapContainer .gm-style-iw-d {
    overflow: auto !important;
  }
}

.gm-style .gm-style-iw-c {
  padding: 0px !important;
}

.gm-style-iw-d {
  overflow: auto !important;
}

.gm-ui-hover-effect {
  top: -4px !important;
  right: -4px !important;
}

/* width */

.overFlowHidden ::-webkit-scrollbar {
  width: 1px;
}

/* Track */

.overFlowHidden ::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

.overFlowHidden ::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */

.overFlowHidden ::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.react-switch-handle {
  box-shadow: none !important;
}

.react-switch-bg div:last-child {
  width: 32px !important;
  z-index: 1;
  left: 0;
  right: unset;
  color: #727272;
  transform: scale(0.6);
}

.react-switch-bg div:first-child {
  width: 32px !important;
  z-index: 1;
  right: 0;
  left: unset;
  color: #77d1da;
  position: absolute !important;
  transform: scale(0.6);
}

/* width */

.homeTypeScroll ::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
}

/* Track */

.homeTypeScroll ::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

.homeTypeScroll ::-webkit-scrollbar-thumb {
  background: #77d1da;
  border-radius: 10px;
}

/* Handle on hover */

.homeTypeScroll ::-webkit-scrollbar-thumb:hover {
  background: #77d1da;
}

.adimnHeader {
  position: fixed;
  height: 87px;
}

/* .gmnoprint {
	margin-top: 30px!important;
} */

/* .searchHeaderFull  .searchHeaderNewOne {
	z-index: -1;
} */

.icheckbox_minimal-blue,
.iradio_minimal-blue .icheckbox_square-green {
  border: 1px solid #dce0e0 !important;
  background-color: #ffffff !important;
  background-image: none !important;
}

.icheckbox_minimal-blue.checked {
  border: 1px solid #77d1da !important;
  background-color: #77d1da !important;
  color: #ffffff !important;
  background-size: 60%;
  background-position: 4px 4px !important;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNzguMzY5IDc4LjM2OSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNzguMzY5IDc4LjM2OTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxwYXRoIGQ9Ik03OC4wNDksMTkuMDE1TDI5LjQ1OCw2Ny42MDZjLTAuNDI4LDAuNDI4LTEuMTIxLDAuNDI4LTEuNTQ4LDBMMC4zMiw0MC4wMTVjLTAuNDI3LTAuNDI2LTAuNDI3LTEuMTE5LDAtMS41NDdsNi43MDQtNi43MDQgICBjMC40MjgtMC40MjcsMS4xMjEtMC40MjcsMS41NDgsMGwyMC4xMTMsMjAuMTEybDQxLjExMy00MS4xMTNjMC40MjktMC40MjcsMS4xMi0wLjQyNywxLjU0OCwwbDYuNzAzLDYuNzA0ICAgQzc4LjQ3NywxNy44OTQsNzguNDc3LDE4LjU4Niw3OC4wNDksMTkuMDE1eiIgZmlsbD0iI0ZGRkZGRiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=) !important;
}

.icheckbox_minimal-green {
  border: 1px solid #dce0e0 !important;
  background-color: #ffffff !important;
  background-image: none !important;
  width: 22px !important;
  height: 22px !important;
}

.icheckbox_minimal-blue.checked {
  border: 1px solid #dce0e0 !important;
  background-color: #77d1da !important;
  color: #77d1da !important;
  background-size: 60%;
  background-position: 3px 3px !important;
}

.ql-editor ol li {
  font-weight: 400;
}

.moreFilter .close {
  float: left;
  font-size: 32px;
  margin-left: 7px;
  opacity: 9;
}

.moreFilter .modal-content {
  border: inherit !important;
  -webkit-box-shadow: inherit !important;
  box-shadow: inherit !important;
  border-radius: 12px;
}

.industrySelect select {
  background-position: 96% 8px !important;
}

.connectionPage .rc-pagination-prev,
.connectionPage .rc-pagination-next {
  padding-top: 3px;
}

.priceTaxSelect select {
  background-position: 94% 20px;
}

@media (max-width: 480px) and (min-width: 320px) {
  .bookModal .modal-content {
    height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  .bookModal .modal-content {
    height: 100%;
  }

  .bookModal {
    max-width: 100% !important;
    margin: 0;
    min-height: 100vh;
  }

  .moreFilter .modal-content {
    border: inherit !important;
  }

  .moreFilter .modal-content {
    border-radius: 0px;
  }

  .hide-search .headerSearch {
    position: static;
    width: 100%;
    padding: 0 7px;
    max-width: 400px;
    margin-bottom: 15px;
    display: block;
  }
}

.positive_name,
.negative_name {
  padding: 0;
  height: 100px;
}

.positive_name .pstvlft {
  border: none;
  padding-right: 5px !important;
  padding: 0;
}

.pstvlft h4,
.pstvrt h4 {
  margin-top: 0;
  font-size: 14px;
  text-align: center;
}

.pstvlft {
  padding: 10px;
  border: 1px solid #cdcdcd;
}

.pstvrt {
  padding: 10px;
  border: 1px solid #cdcdcd;
}

.pstvlft .col-xs-12,
.pstvrt .col-xs-12 {
  margin-bottom: 1px;
}

.pstvlft div.filepicker,
.pstvrt div.filepicker {
  padding: 0;
  margin: 0;
  line-height: 10px;
  font-size: 14px;
}

.positive {
  padding-left: 0;
  padding-right: 5px;
}

.negative {
  padding-right: 0;
  margin-right: -5px;
  padding-left: 5px;
}

.negative_name .pstvrt {
  border: none;
  padding: 0;
  padding-left: 5px;
}

.disctag {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}

.mapContainer .gm-style {
  background-color: #aad9fc !important;
}

.homeBanner .slick-initialized .slick-slide {
  padding-right: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.gm-bundled-control div:nth-child(1) {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 24px,
    rgba(0, 0, 0, 0.04) 0px 0px 0px 1px !important;
}

.dz-success-mark {
  display: none !important;
}

.dz-error-mark {
  display: none !important;
}

.geosuggest__suggests--hidden {
  border-width: 0px !important;
}

.captionContent___30kw2 {
  margin: 0 auto;
  font-weight: 600;
}

.blankInput>div>div>span {
  color: red;
  position: relative;
  top: -2px;
}

iframe#launcher {
  bottom: 12px !important;
}

.idButton {
  border-color: #77d1da;
  background-color: #77d1da;
  color: #fff;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
}

.idButton:hover,
.idButton:active:hover,
.idButton:focus {
  border-color: #49d5dc;
  background-color: #49d5dc;
  color: #fff;
}

/* .modal-backdrop.in{
   background: red !important;
} */

.loadersetmid .Loader__message {
  position: relative !important;
  top: -30% !important;
}

.idUploadSection {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.idUploadSectionCss .filepicker {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 35px 0;
}

.idUploadSectionCss .filepicker .dz-message {
  margin: 0 auto 0 auto;
}

.idUploadSectionCss .dz-image-preview {
  margin-top: 25px;
}

.disctag {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}

.mapInfoWindow .disctag {
  right: 24px;
}

s.homestrike {
  position: relative;
  font-weight: 500;
  margin-right: 4px;
}

s.homestrike:before {
  content: '';
  width: 104%;
  border-bottom: 1.5px solid #232323;
  display: inline-block;
  position: absolute;
  margin: 0 auto;
  text-align: center;
  top: 50%;
  left: -2px;
  overflow: hidden;
}

.admin .u-borderTransparent {
  display: none !important;
}

s.discstrikeView {
  display: inline-block;
  text-decoration: none;
  position: relative;
}

s.discstrikeView::before {
  content: '';
  position: absolute;
  display: block;
  border-bottom: 1px solid #fff;
  width: 93%;
  top: 50%;
  left: 4px;
}

.lineStrike span span {
  text-decoration: line-through;
}

.rightAlign {
  margin-right: 5px;
}

.genderBlock {
  margin-bottom: 5px;
  margin-top: 16px;
}

.intendBlock {
  margin-bottom: 7px;
}

.verificationModal .modal-header {
  padding: 25px 15px 15px;
  border-bottom: none;
}

.verificationModal .modal-dialog {
  top: 30%;
}

.verifyModal .modal-dialog {
  top: 0%;
}

.verificationModal .close {
  /* float: right; */
  font-size: 22px;
  font-weight: 500;
  line-height: 0;
  color: #000;
  opacity: 3;
  background: #fff !important;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  box-shadow: 0 0 5px 0px #eeedef;
  position: absolute;
  top: -10px;
  right: -9px;
}

.verificationModal .modal-body {
  padding: 5px 35px 30px;
}

.editPhoneNumberAddField .modal-dialog {
  top: 20%;
}

.MapPopupList .gm-style .gm-style-iw-c {
  border-radius: 8px !important;
  border-top-left-radius: 0px !important;
}

.MapPopupList .gm-ui-hover-effect {
  display: none !important;
}

.menuActive .sideNav {
  font-weight: 700;
  color: #484848;
}

.menuActive .sideNav,
.sideNav:hover {
  color: #484848;
}

.menuActive .sideNav,
.sideNav:focus {
  color: #484848;
}

/************formdisable start*************/

.formDisaple .form-control[disabled] {
  background-color: #eee !important;
  opacity: 1;
}

/************ member page search header ***************/

.connectionCommon .nameSearch {
  width: 100%;
  top: 0;
}

.connectionCommon .nameSearch input {
  box-shadow: none;
  min-height: 35px;
}

.connectionCommon .nameSearch input::placeholder {
  color: #808080 !important;
  font-size: 14px !important;
  font-weight: 400;
}

.connectionCommon .nameSearch input:focus {
  border-color: #77d1da;
}

.connectionCommon .editProfile .geosuggest__input {
  border: 1px solid #ccc !important;
  border-radius: 4px;
  box-shadow: none;
  height: 35px;
  top: 0.5px;
  position: relative;
  padding-left: 10px;
}

.connectionCommon .editProfile .geosuggest__input::placeholder {
  color: #808080 !important;
  font-size: 14px !important;
  font-weight: 400;
  position: relative;
  line-height: 20px;
}

.connectionCommon .css-1k1e4o2-control {
  border: 1px solid #ccc !important;
  border-radius: 4px;
  box-shadow: none;
  min-height: 35px;
  top: 0.5px;
  position: relative;
}

@-moz-document url-prefix() {
  .connectionCommon .css-1k1e4o2-control {
    top: 1px;
    position: relative;
  }

  .connectionCommon .css-frv6jl-control {
    top: 1px;
  }

  .connectionCommon .editProfile .geosuggest__input::placeholder {
    color: hsl(0, 0%, 50%) !important;
    font-size: 14px !important;
    font-weight: 400;
  }
}

.industry {
  position: relative;
}

.industry .btn-default {
  position: relative;
  top: -2px;
  min-height: 35px;
  width: 100%;
  color: #808080;
  text-align: left;
}

.industry .btn-default:hover,
.industry .btn-default:focus {
  background-color: transparent;
  outline: 0 !important;
  border-color: #ccc;
  box-shadow: none;
  color: #808080;
}

.connectionCommon .css-frv6jl-control {
  min-height: 35px !important;
  border-color: #77d1da !important;
  border-width: 1px !important;
  box-shadow: none !important;
  border-radius: 4px;
  position: relative;
  top: 0.5px;
  background-image: url('assets/connectunivers.png') !important;
  background-repeat: no-repeat;
  background-position: 5px;
  background-size: 18px !important;
  padding-left: 24px;
}

.connectionCommon .editProfile .geosuggest__input:focus {
  min-height: 35px !important;
  border-color: #77d1da !important;
  border-width: 1px !important;
  box-shadow: none !important;
}

.connectionCommon .css-lj0xvp-container:hover {
  box-shadow: none;
}

.connectionCommon .nameSearch input {
  background-image: url('assets/profpic.png') !important;
  background-repeat: no-repeat;
  background-position: 5px;
  background-size: 18px !important;
  padding-left: 30px;
  filter: grayscale(1);
}

.connectionCommon .editProfile .geosuggest__input {
  background-image: url('assets/connectlocation.png') !important;
  background-repeat: no-repeat;
  background-position: 5px;
  background-size: 18px !important;
  padding-left: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.connectionCommon .css-dvua67-singleValue {
  color: #484848 !important;
  font-weight: 300 !important;
}

.industry .btn-default {
  background-image: url('assets/connectindus.png') !important;
  background-repeat: no-repeat !important;
  background-position: 5px !important;
  background-size: 18px !important;
  padding-left: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.industry .btnSecondary {
  background-image: url('assets/connectindus2.png') !important;
  background-repeat: no-repeat !important;
  background-position: 5px !important;
  background-size: 18px !important;
  padding-left: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.connectionCommon .css-1k1e4o2-control {
  background-image: url('assets/connectunivers.png') !important;
  background-repeat: no-repeat;
  background-position: 5px;
  background-size: 18px !important;
  padding-left: 24px !important;
}

.connectionCommon .css-151xaom-placeholder {
  width: 100%;
  padding-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: hsl(0, 0%, 50%);
  margin-left: 2px;
  margin-right: 2px;
}

.connectionCommon .universityDefault input {
  background-image: url('assets/connectunivers.png') !important;
  background-repeat: no-repeat;
  background-position: 5px;
  background-size: 18px !important;
  padding-left: 24px;
}

.memberLocationSearch .geosuggest__suggests {
  border: 1px solid #ccc !important;
  width: 100.1% !important;
  left: 0 !important;
}

.memberLocationSearch .geosuggest__suggests--hidden {
  display: none;
}

@media screen and (max-width: 767px) {
  .industry .btn-default {
    top: 0;
  }

  @-moz-document url-prefix() {
    .industry .btn-default {
      top: -2px;
    }
  }
}

/*********** invite a friend popup *************/

.invitePhone .form-control {
  width: 100% !important;
  height: 36px;
}

.invitePhone ul.country-list {
  max-height: 120px !important;
  width: 100%;
  margin: 10px 0 10px 0px !important;
}

.invitePhone .react-tel-input .flag-dropdown.open-dropdown {
  width: 100%;
  border-radius: 5px;
}

.invitePhone .react-tel-input .flag-dropdown.open-dropdown .selected-flag {
  border-right: 1px solid #cacaca;
  height: 32.5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

strong {
  font-weight: bold;
}

/**************** wave loader ******************/

.waveLoader {
  position: relative;
  text-align: center;
  width: 100px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
}

.waveDot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
  background: #77d1da;
  animation: wave 1.2s linear infinite;
}

.waveDot:nth-child(2) {
  animation-delay: -0.9s;
}

.waveDot:nth-child(3) {
  animation-delay: -0.7s;
}

@keyframes wave {

  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}

/**************** wave loader End ******************/

/**************** city page other listing item *****/

.onlyShow {
  display: none;
}

.listingOnly {
  height: 80vh;
}

.listingOnly .onlyShow {
  display: block;
}

.listingOnly .hideThis {
  display: none;
}

.listingOnly .borderhide {
  border: none;
}

/**************** city page other listing end ******/

.hostfullForm .form-control {
  height: 40px;
}

.confirmModal .modal-header {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

.confirmModal .modal-dialog {
  top: 25%;
}

.makeSplOffer .DateInput_input {
  padding-top: 3px;
  padding-bottom: 0;
}

.makeSplOffer input.form-control {
  height: 42px;
  border-radius: 0px;
}

.diclineModel input.form-control {
  height: 42px;
  border-radius: 0px;
}

.cityPageHight {
  height: 77vh;
}

.pmsSelect select {
  background-position: 96% 8px;
}

/************************* ConciergeForm Start *********************************/

/* .conciergeForm .geosuggest__suggests {
	left: 0px !important;
    border: 1px solid #aaa !important;
} */

.conciergeForm input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #302f2f9c !important;
}

.conciergeForm input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #302f2f9c !important;
}

.conciergeForm input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #302f2f9c !important;
}

.conciergeForm .DateInput_input {
  font-size: 14px;
}

.golightlyConcierge .geosuggest__suggests {
  width: 100% !important;
  left: 0px !important;
  border: 1px solid #ccc !important;
  border-top: inherit !important;
}

.golightlyConcierge .geosuggest__input:focus {
  border: 1px solid #ccc;
  outline: 0;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

.StripeElement {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  height: 40px;
  border: 1px solid #aaa;
  background-color: #fff;
  margin-bottom: 20px;
  padding-top: 10px;
}

/**************************** Place Holder Form Start ***************************/

.placeHolderFont ::-webkit-input-placeholder {
  color: #aaa !important;
  font-weight: normal !important;
}

.placeHolderFont ::-moz-placeholder {
  color: #aaa !important;
  font-weight: normal !important;
}

.placeHolderFont :-ms-input-placeholder {
  color: #aaa !important;
  font-weight: normal !important;
}

.placeHolderFont :-o-input-placeholder {
  color: #000000 !important;
  font-weight: normal !important;
}

.placeHolderFont input:-webkit-autofill,
.placeHolderFont input:-webkit-autofill:hover,
.placeHolderFont input:-webkit-autofill:focus,
.placeHolderFont input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background-color: #ffffff !important;
}

/**************************** Place Holder Form End ***************************/

.rc-pagination {
  font-family: 'Lato', sans-serif !important;
}

.createBlockPosts a.closepic {
  background-image: url('assets/closepic.png');
  background-repeat: no-repeat;
  background-size: 23px;
  cursor: pointer;
  display: block;
  position: absolute;
  width: 23px;
  height: 23px;
  top: -11px;
  right: -11px;
  background-color: #fff;
  border-radius: 50%;
}

.createBlockPostsPanel .panel-body {
  padding: 0px;
}

.createBlockPosts {
  padding-left: 0;
}

.threedot li {
  list-style: none;
  min-width: auto;
  position: relative;
}

.threedot {
  display: block;
  position: absolute;
  right: 20px;
}

.threedot li .caret {
  display: none;
}

.threedot li ul {
  box-shadow: 0px 0px 15px #eee;
  position: absolute;
  left: auto;
  right: 10px;
  padding: 10px 10px;
  border: none;
  min-width: 100px;
}

.threedot li ul button.editpst:before {
  content: '';
  width: 115%;
  display: block;
  position: absolute;
  border-bottom: 1px solid;
  left: -5px;
  top: 148%;
  color: #77d1da;
}

.threedot li ul button.editpst,
.threedot li ul a.delpost {
  background: #fff;
  color: #77d1da;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 15px;
  text-decoration: none;
  border: none;
  position: relative;
}

.threedot li ul button.editpst:focus,
.threedot li ul a.delpost:focus {
  background: #77d1da;
  color: #fff;
}

.threedot li ul a.delpost {
  margin-bottom: 0px;
}

.flagadmin {
  background-image: url('assets/admin-flag.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  display: block;
  width: 100%;
  height: 100%;
  max-width: 90px;
  padding-top: 5%;
  position: relative;
  right: -50px;
}

.flagHeightHustle {
  padding: 0px;
  height: 30px;
}

@media screen and (max-width: 767px) {
  .flagadmin {
    padding-top: 10%;
  }

  .flagHeightHustle {
    padding: 0px;
  }
}

.flagMarginGohustle {
  margin: 0 0 3px auto;
}

.flagMargin {
  margin: 0 0 0 auto;
}

.clickpic {
  margin-bottom: 15px;
}

.threedot a.dropdown-toggle {
  transform: rotate(90deg);
  display: block;
}

.threedot a.dropdown-toggle {
  transform: rotate(90deg);
  display: block;
}

.blogContentManage {
  word-break: break-word;
  min-width: 400px;
  width: 400px;
}

/**************************** Place Holder Form End ***************************/

.selectcommunity .rw-widget>.rw-widget-container::after {
  position: relative;
  top: 25px;
}

.selectcommunity .rw-widget>.rw-widget-container {
  height: auto !important;
  padding: 0 20px !important;
  display: flex;
  align-items: center;
}

.selectcommunity .rw-widget-picker>* {
  vertical-align: middle !important;
  font-size: 18px !important;
  padding: 12px 0;
  /* display: flex;
align-items: center; */
}

.selectcommunity .rw-multiselect-tag {
  font-size: 14px !important;
  margin: 3px 0 3px 3px;
}

.selectcommunity .rw-multiselect .rw-input-reset {
  padding: 0 !important;
}

.siteadmincommunity .rw-widget-picker {
  font-size: 14px !important;
}

.siteadmincommunity .rw-multiselect-tag-btn {
  vertical-align: text-top !important;
}

.siteadminlocationadd .geosuggest__input {
  font-size: 14px !important;
  font-weight: normal !important;
  height: 40px !important;
}

.siteadminlocationadd .geosuggest {
  height: 40px !important;
}

.siteadminlocationadd .geosuggest__input::placeholder {
  color: #999999 !important;
}

.editlocaladd .geosuggest__input {
  font-size: 14px !important;
}

.siteadminselectcommunity .rw-multiselect-tag-btn {
  vertical-align: baseline !important;
}

.siteadminselectcommunity ul .rw-multiselect-tag,
.siteadmincommunity ul .rw-multiselect-tag {
  margin-top: 3px !important;
  margin-left: 3px !important;
  margin-bottom: 3px !important;
  vertical-align: middle !important;
  height: auto !important;
  font-size: 14px !important;
}

.siteadminselectcommunity .rw-multiselect .rw-input-reset {
  height: 40px !important;
  font-size: 14px !important;
}

.siteadminselectcommunity .rw-multiselect>.rw-widget-picker {
  height: 92px !important;
  padding: 0 12px !important;
}

.siteadmincommunity .rw-multiselect .rw-input-reset {
  height: 40px !important;
  font-size: 14px !important;
  padding: 0 !important;
}

.siteadmincommunity .rw-multiselect>.rw-widget-picker {
  height: auto !important;
  padding: 0 12px !important;
}

.siteadmincommunity .rw-select>* {
  right: 12px !important;
  top: -10px !important;
}

.siteadminselectcommunity .rw-select>* {
  right: 12px !important;
  top: -10px !important;
}

.siteadminlocatinaddress .popularLocationAutoComplete {
  height: 40px;
}

.siteadminlocatinaddress .geosuggest__input {
  height: 40px !important;
  font-weight: normal;
  font-size: 14px;
}

.siteadminlocatinaddress .geosuggest__input::placeholder {
  color: #999999 !important;
}

.siteadminlocatinaddress ul {
  z-index: 999 !important;
}

.selectcommunity .rw-select>* {
  right: 10px !important;
}

.selectcommunity .rw-popup-container {
  position: static !important;
}

.selectcommunity .rw-popup-transition {
  padding: 0px !important;
}

.blogContentLoader .Loader__message {
  top: 100px !important;
  position: relative;
}

/************************** Blog Search Placeholder Color Start **************************/

.blogSearchPlaceColor ::placeholder {
  color: #77d1da;
  opacity: 1;
}

.blogSearchPlaceColor :-ms-input-placeholder {
  color: #77d1da;
}

.blogSearchPlaceColor ::-ms-input-placeholder {
  color: #77d1da;
}

/************************** Blog Search Placeholder Color End **************************/

.socialBlogDeleteIcon svg {
  font-size: 18px;
  fill: #77d1da;
}

.addEventForm .geosuggest__input {
  border: 1px solid #aaa;
  box-shadow: none;
  height: 40px;
  border-radius: 2px;
}

.addEventFormStartDate .css-bg1rzq-control {
  height: 40px;
  border-radius: 2px;
  border: 1px solid #aaa !important;
  box-shadow: none !important;
}

.addEventFormStartDate .css-1szy77t-control {
  border: 1px solid #aaa !important;
  box-shadow: none !important;
  height: 40px;
  border-radius: 2px;
}

.addEventFormStartDate .css-1szy77t-control:hover {
  border-color: #aaa !important;
  box-shadow: none !important;
  height: 40px;
  border-radius: 2px;
}

.eventDateRange .DateInput_fang {
  display: none;
}

.eventDateRange .DateRangePicker_picker {
  top: 54px !important;
}

.userPostMultiSelect .css-1szy77t-control {
  border-color: #aaa !important;
  box-shadow: none !important;
}

.userPostMultiSelect .css-1szy77t-control:hover {
  border-color: #aaa !important;
  box-shadow: none !important;
}

.postModelNew {
  width: 100%;
  max-width: 800px;
  margin: 30px auto 0 auto;
}

.errorMessage {
  margin-bottom: 5px;
  display: block;
  color: #ff0000;
  font-size: 14px;
  font-weight: normal;
}

.videoClip video {
  width: 100%;
  height: 550px;
}

.viewListingImageSlider .modal-dialog {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.viewListingImageSlider .modal-body {
  padding: 0px;
}

.viewListingImageSlider .modal-content {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.viewListingImageSlider .modal-header {
  padding-right: 0px;
  padding-bottom: 6px;
}

.viewListingImageSlider .slick-initialized .slick-slide {
  padding-right: 0px !important;
}

.viewListingImageSlider .modal-header {
  border: none;
}

.viewListingImageSlider .slick-next {
  background-color: transparent !important;
  background: transparent !important;
  background-image: url('assets/rightarrow.png') !important;
  background-position: 50% 50%;
  background-repeat: no-repeat !important;
  background-size: 20px 20px !important;
  top: 48%;
}

.viewListingImageSlider .slick-prev {
  background-color: transparent !important;
  background: transparent !important;
  background-image: url('assets/leftarrow.png') !important;
  background-position: 50% 50%;
  background-repeat: no-repeat !important;
  background-size: 20px 20px !important;
}

.viewListingImageSlider .close {
  font-size: 36px !important;
  font-weight: normal;
  color: #ffffff;
  opacity: 2.7;
  margin-left: 5px;
  outline: none !important;
  margin-right: 4px;
}

.viewListingSlideritemText {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  margin: 12px auto 12px;
  text-align: center;
  line-height: 18px;
}

.viewListingImageSlider .slick-prev {
  left: -40px;
}

.viewListingImageSlider .slick-next {
  right: -40px;
}

.noPaddingSlider {
  padding: 0px;
}

.socialBlogBgImage {
  width: 100%;
  height: auto;
  background-size: cover !important;
  padding-top: 100%;
  background-position: 50% 50% !important;
  border-radius: 50%;
  background-repeat: no-repeat !important;
}

@media screen and (max-width: 1250px) and (min-width: 1201px) {

  .rentAllHeader.navbar-default .navbar-nav>li>a,
  .rentAllHeader.navbar-default .navbar-nav>li>form>button {
    padding: 0px 15px;
  }
}

.videoBgImage {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
  width: 100%;
  height: auto;
  padding-top: 60%;
}

.becomeAHostVideo video {
  width: 100%;
  height: 200px;
}

.videoLoader .videoLoaderHead .Loader__background {
  z-index: 9 !important;
}

.popUpSlider .modal-content {
  background-color: transparent;
  box-shadow: inherit;
  border: inherit;
}

.popUpSlider .modal-dialog {
  width: 900px;
}

.popUpSlider .image___xtQGH {
  width: auto;
  height: 500px;
  margin: 0px auto 0 auto;
}

.popUpSlider .slide___3-Nqo {
  padding-bottom: inherit !important;
  padding-top: 0% !important;
  height: 650px;
}

.videoLoader .Loader__message {
  position: relative;
  top: 60px;
}

.viewListingClip .Loader__message {
  position: relative;
  top: 300px;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .popUpSlider .modal-dialog {
    width: 600px;
  }

  .popUpSlider .modal-open .modal {
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .popUpSlider .modal-dialog {
    width: 100%;
    margin: 0px;
    padding: 0px 15px;
  }

  .popUpSlider .modal-open .modal {
    padding-left: 0px !important;
  }
}

.popUpSlider {
  background: rgba(0, 0, 0, 0.8);
}

@supports (-webkit-touch-callout: none) {
  .popUpSlider {
    background: rgba(0, 0, 0, 0.5);
  }
}

.connectionDate .DateInput_input {
  height: 33px;
  font-size: 14px;
}

/**************************** Add User Blog Post Start ***************************/

.addUserBlogQuil .ql-toolbar.ql-snow {
  padding: 0px;
  border: none !important;
  display: inline-block;
  vertical-align: top;
}

.addUserBlogQuil .ql-snow.ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 0;
  width: 20px;
  margin: 0 !important;
}

.addUserBlogQuil .ql-snow.ql-toolbar button:hover {
  color: #333;
}

.addUserBlogQuil .ql-snow.ql-toolbar button:hover .ql-stroke {
  stroke: #333;
}

.addUserBlogQuil div.filepicker {
  padding: 17px 18px 5px 5px !important;
}

.UserBlogExploreQuil div.filepicker {
  padding: 17px 18px 5px 5px !important;
}

.commonFilepicker div.filepicker {
  text-align: center;
  padding: 0px 10px;
  background-color: transparent;
  border-radius: 5px;
  height: auto;
  min-height: 20px;
  border: none;
  margin-bottom: 0px;
  position: relative;
  z-index: 2;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}

.filePickerImage {
  position: absolute;
  left: 0px;
  right: 0;
  top: 0;
  font-size: 22px;
  vertical-align: top;
}

/**************************** Add User Blog Post End ***************************/

.videoClipAddBlogUser video {
  width: 100%;
  height: 350px;
}

.videoBlogListImage video {
  width: 100%;
  /* height: 377px; */
  /* object-fit: cover; */
  object-fit: fill;
}

.userBlogListImage .ql-snow .ql-tooltip {
  position: absolute;
  transform: translateY(-60px);
  left: 8px !important;
}

.userBlogListImage .ql-editor:not(.frontend) {
  overflow-y: auto;
  overflow-x: auto;
}

.userBlogListImage .ql-container {
  border-radius: 4px !important;
}

.userBlogListImage .ql-mention-list {
  padding: 0px;
  list-style: none;
  margin: 0;
  padding: 0px !important;
  height: auto;
  overflow: auto;
  max-height: 150px;
  border: 1px solid #ccc;
  background-color: #ffffff;
}

.userBlogListImage .ql-mention-list-item:hover {
  background-color: #ebebeb;
}

.userBlogListImage .ql-mention-list-item {
  margin-bottom: 0px;
  padding: 6px 6px;
}

.userBlogListImage .ql-mention-list-container {
  left: 0 !important;
  right: 0px !important;
  margin: 0px 15px;
}

/******************** Like Model Scroll Bar Start *************************/

.likeModel ::-webkit-scrollbar {
  width: 5px;
}

.likeModel ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #77d1da;
  border-radius: 10px;
}

.likeModel ::-webkit-scrollbar-thumb {
  background: #77d1da;
  border-radius: 10px;
}

.likeModel ::-webkit-scrollbar-thumb:hover {
  background: #77d1da;
}

.likeModelHead .modal-dialog {
  width: 100%;
  margin: 30px auto;
  padding: 0px 15px;
  max-width: 400px;
}

/******************** Like Model Scroll Bar End *************************/

.icheckbox_minimal-blue.disabled:hover>.iCheck-helper {
  cursor: not-allowed !important;
}

/*************************** Social Mention****************************/

.mentionedUser,
span.mention {
  color: #77d1da;
  font-weight: 500;
}

/************************* Mentions Class Css Start **********************/
.mentionClass .mentionClass__highlighter {
  height: auto !important;
  overflow: auto !important;
  color: #484848 !important;
  min-height: 100px;
  height: auto !important;
  overflow: auto !important;
  color: #484848 !important;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.mentionClass .mentionClass__input {
  height: auto !important;
  overflow: auto !important;
  color: #484848 !important;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  min-height: 100px;
  border-radius: 4px;
}

.mentionClass .mentionClass__suggestions__list {
  padding: 0px !important;
  height: auto;
  overflow: auto;
  max-height: 200px;
  border: 1px solid #ccc;
}

.mentionClass .mentionClass__suggestions {
  min-width: 280px !important;
  top: 14px !important;
}

.metionClassAvatar {
  width: 100%;
  max-width: 44px;
  margin: 0 auto;
  border-radius: 50%;
  height: auto;
  display: inline-block;
}

.mentionClass .mentionClass__suggestions__item {
  margin-bottom: 0px;
  padding: 6px 6px;
}

.mentionClass .mentionClass__suggestions__item:hover {
  background-color: #ebebeb;
}

/************************* Mentions Class Css End **********************/
/************************ Post Commants Start *************************/
.mentionAvatarSection {
  display: table-cell;
  vertical-align: top;
  width: 44px;
}

.mentionAvatarTextSection {
  display: table-cell;
  padding-left: 8px;
  vertical-align: middle;
  word-break: break-word;
}

.displayTable {
  display: table;
  width: 100%;
}

.displayTableRow {
  display: table-row;
  width: 100%;
}

.mentionAvatarSection>img {
  width: 100%;
  max-width: 44px;
  margin: 0 auto;
  border-radius: 50%;
  height: auto;
  display: inline-block;
}

/************************ Post Commants End *************************/
.addPhoneNumberVerify .react-tel-input input[type='tel'] {
  width: 100% !important;
}

.verifyStepsBg .panel-default>.panel-heading {
  background-color: #77d1da;
}

.verifyStepsBg .verifyProfileSection .panel-heading {
  display: none;
}

.verifyStepsBg .verifyProfileSection .panel-body {
  padding: 0px;
}

.verifyStepsBg .verifyProfileSection {
  border: 0;
  padding: 15px 0px 0px;
  margin-bottom: 0;
}

.checkbox label {
  padding-left: 25px;
  vertical-align: middle;
  word-break: break-word;
}

.checkbox label input[type='checkbox'] {
  position: absolute;
  border: none;
  appearance: none;
  margin-left: -25px;
  margin-top: 1px;
}

.checkbox label input[type='checkbox']:after {
  content: '';
  display: block;
  cursor: pointer;
}

.checkbox label input[type='checkbox']:checked:after {
  width: 6px;
  height: 12px;
  border: 2px solid #fff;
  border-left: none;
  border-top: none;
  position: absolute;
  left: 6px;
  top: -2px;
  transform: rotateZ(45deg);
  z-index: 20;
}

.checkbox label input[type='checkbox']:before {
  content: '';
  cursor: pointer;
  position: absolute;
  top: -4px;
  left: 0;
  display: inline-block;
  padding: 8px;
  border: 1px solid #c7c5c5;
  border-radius: 3px;
  z-index: 1;
  transition: background ease-in-out 0.1s;
}

.checkbox label input[type='checkbox']:checked:before {
  background: #77d1da;
  border: 1px solid #77d1da;
}

.checkbox label input[type='checkbox']:disabled:before {
  background: #eee;
  cursor: not-allowed;
}

.checkbox label input[type='checkbox']:after {
  cursor: not-allowed;
  border-color: #77d1da;
}

.checkbox label input[type='checkbox']:focus,
.checkbox label input[type='checkbox']:checked {
  outline: none;
  border: none;
}

.soloSlider .slick-list {
  height: 393px;
  padding-top: 7px
}

@media screen and (max-width: 1199px) and (min-width: 768px) {
  .soloSlider .slick-list {
    height: 100%;
    padding-bottom: 50px
  }
}

.soloSlider .slick-initialized .slick-slide {
  padding-right: 10px !important;
  padding-left: 7px !important;
}

.tripDataPicker .DateInput_input {
  font-size: 13px;
  padding: 5px 11px 9px;
  line-height: inherit;
}

.tripDataPicker .DateInput {
  width: 100px;
  background: transparent;
}

div.filepicker {
  padding: 17px 5px 5px 5px !important;
}

@media screen and (max-width: 767px) {
  .soloSlider .slick-list {
    height: auto;
  }
}

.goSoloTripForm .geosuggest__suggests {
  width: 100% !important;
  left: 0 !important;
}

@media screen and (max-width: 1199px) and (min-width: 768px) {
  .userLayoutHeader .navbar-collapse.collapse {
    display: block !important;
  }

  .userLayoutHeader .navbar-header {
    float: left;
    margin-bottom: 0px;
  }
}

.homeGridPlaceSuggest .geosuggest__input {
  height: 40px !important;
  border-radius: 10px !important;
  font-size: 16px;
  border: 1px solid #77d1da !important;
  box-shadow: none;
  padding-top: 4px;
  margin-top: 10px;
}

.homeGridPlaceSuggest .geosuggest__input:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}

.homeGridPlaceSuggest .geosuggest__suggests {
  width: 100% !important;
  left: 0px !important;
  margin-top: 0px;
  border: 1px solid #dce0e0;
}

.localSpotModel .modal-header .close {
  background-color: #484848;
  opacity: 1;
  color: #fff;
  border-radius: 50%;
  margin: 0px;
  padding: 0px 5px 2px 5px;
  font-size: 19px;
  border: 1px solid #484848;
}

.localSpotModel .modal-title {
  font-size: 28px;
  text-align: center;
  font-weight: 700;
  line-height: 31px;
}

.localSpotModel .modal-header {
  border-bottom: 0px !important;
}

.localSpotModel .modal-content {
  border: 1px solid #77d1da !important;
}

.dashBoardPanel .panel-heading {
  background-color: #77d1da;
  color: #fff;
  border-radius: 0px;
  padding-left: 90px;
}

.dashBoardDaimondImage {
  position: absolute;
  bottom: 3px;
  right: 3px;
  width: 28px;
  height: 28px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 2px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
}

.viewProfileDiamondImage {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 32px;
  height: 32px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 0px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
}

.diamondImageHeader {
  position: absolute;
  bottom: 18px;
  right: 11px;
  width: 13px;
  height: 13px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 0px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
}

.inboxDiamondImage {
  position: absolute;
  bottom: 5px;
  right: 6px;
  width: 15px;
  height: 15px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 1px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
}

.linkSection {
  position: relative;
  display: inline-block;
}

.viewMessageDaimondImage {
  position: absolute;
  bottom: 10px;
  right: 9px;
  width: 18px;
  height: 18px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 0px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
}

.profilePhotoDaimondImage {
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: 31px;
  right: 27px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 2px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
}

.reservationDaimondImage {
  position: absolute;
  width: 15px;
  height: 15px;
  bottom: 6px;
  right: 6px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 1px;
  border: 1px solid #b2a7a7;
  box-shadow: 2px 2px 4px #77d1da;
}

.itinaryDaimondImage {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 17px;
  height: 17px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 1px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
}

.userDaimondImage {
  width: 25px;
  height: 25px;
  bottom: 23px;
  right: 23px;
  position: absolute;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 1px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
}

.listintroDaimondImage {
  position: absolute;
  width: 17px;
  height: 17px;
  bottom: 10px;
  right: 13px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 1px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
}

.meetHostDaimondImage {
  position: absolute;
  right: 4px;
  bottom: 4px;
  width: 17px;
  height: 17px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 1px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
}

.contactHostDaimondImage {
  position: absolute;
  bottom: 14px;
  right: 19px;
  width: 21px;
  height: 21px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 1px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
}

.minimumServiceFee {
  color: #77d1da;
  cursor: pointer;
  display: inline-block;
}

.reactQuill .ql-blank:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}

.reactQuill .ql-editor:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}

.reactQuill .ql-container {
  border: none;
  background-color: #fff !important;
}

.reactQuill .ql-snow {
  border: none;
  background-color: transparent !important;
  margin-bottom: 0px;
}

.reactQuill .ql-snow.ql-toolbar button {
  height: 34px;
  padding: 3px 5px;
  width: 36px;
  border-radius: 4px;
  background-color: transparent;
}

.reactQuill .ql-toolbar {
  border: none;
  padding: 0;
  border-radius: 4px;
}

.reactQuill .ql-editor:not(.frontend) {
  min-height: 8em !important;
  max-height: 8em !important;
}

@media screen and (max-width: 767px) {
  .reactQuill .ql-editor:not(.frontend) {
    min-height: 15em !important;
    max-height: 15em !important;
  }
}

.daimondIconPayment {
  width: 100%;
  max-width: 32px;
  height: auto;
}

.daimondTableCell {
  display: table-cell;
  vertical-align: top;
}

.daimondTableCellIconWidth {
  width: 60px !important;
  padding: 2px !important;
  border-radius: 0px !important;
  margin-right: 10px;
}

.daimondTableCellText {
  color: #484848;
}

.daimondText {
  font-size: 18px;
}

.daimondTextLink {
  font-size: 18px;
}

.daimondTableCellText:hover {
  color: #77d1da;
  text-decoration: underline;
}

.daimondFontBold {
  font-weight: 600;
}

.vtrMiddle {
  vertical-align: middle;
}

.meetUpDaimondImage {
  position: absolute;
  right: 21px;
  bottom: 21px;
  width: 21px;
  height: 21px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 2px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
}

.blogExploreAvatar {
  position: absolute;
  bottom: 15px;
  right: 6px;
  width: 15px;
  height: 15px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 1px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
}

.blogExploreAvatarUser {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 19px;
  height: 19px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 2px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
}

.positionRelative {
  position: relative;
}

.displayInlineBlock {
  display: inline-block;
}

.memberDaimondImage {
  width: 20px;
  height: 20px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 1px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px #77d1da;
  position: relative;
  right: 33px;
  top: 33px;
}

@media screen and (max-width: 640px) {
  .meetUpDaimondImage {
    right: 15px;
    bottom: 12px;
  }
}

.bffAutoSuggest .react-autosuggest__input {
  font-size: 14px !important;
  padding: 10px !important;
}

.bffAutoSuggest .react-autosuggest__suggestions-container--open {
  border: 0px;
  box-shadow: 0px 3px 5px #bdbcbc63;
}

.bffAutoSuggest .react-autosuggest__suggestions-list {
  position: relative;
  list-style-type: none;
  margin: 0px;
  width: 100%;
  border-top-width: 0px;
  width: 100%;
  padding: 0px;
  z-index: 5;
  height: 152px;
  overflow: auto;
}

.profilePhoto div.filepicker {
  padding: 10px !important;
}

.css-kj6f9i-menu {
  z-index: 10 !important;
  background-color: #fff !important;
}

.descriptionQuillStyle ul {
  background-color: #77d1da;
  padding: 30px 30px 18px !important;
  margin: 12px 0px;
  border-radius: 30px;
}

.descriptionQuillStyle li {
  padding-left: 10px !important;
  color: #fff;
  margin-bottom: 12px;
  font-size: 18px;
  font-family: 'Lato', sans-serif !important;
}

.descriptionQuillStyle .ql-editor p {
  color: #77d1da;
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  font-family: 'Lato', sans-serif !important;
}

@media screen and (max-width: 767px) {
  .descriptionQuillStyle ul {
    padding: 15px 15px 7px !important;
  }
}

.sortable-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  padding: 0px 0px 0px;
  max-width: 1080px;
  overflow-y: auto;
}

.sortable-item {
  display: flex;
  padding: 15px;
  position: relative;
  max-width: 33.3%;
  flex-basis: 100%;
  border-radius: 0px;
  flex-direction: column;
  text-align: center;
}

.sortable-inner-item {
  width: 100%;
}

.SortableList {
  position: relative;
  z-index: 0;
  background-color: #F3F3F3;
  border: 1px solid #EFEFEF;
  border-radius: 3px;
  outline: none;
  width: 400px;
  height: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #E0E0E0;
  list-style: none;
  padding: 0;
}

.SortableItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: #FFF;
  border-bottom: 1px solid #EFEFEF;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
}

.SortableHelper {
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 6px, rgba(0, 0, 0, 0.075) 0px 1px 4px;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
}

.photoSortableeMobile {
  padding: 0px;
}

@media screen and (max-width: 767px) {
  .sortable-item {
    max-width: 100%;
  }
}

.gosoloTripFormDate .DateInput {
  width: 45% !important;
}

.gosoloTripFormDate .DateRangePicker_picker {
  top: 56px !important;
}

.gosoloTripFormDate .DateInput_fang {
  top: 45px !important;
}

.gosoloTripFormDate .DateInput_input {
  font-size: 13px;
  padding: 11px 9px 10px;
  line-height: 19px;
}

.gosoloBookingManageTable .table>thead>tr>th {
  min-width: 150px;
}

.gosoloBookingManageTable .table>thead>tr>th:nth-child(1) {
  min-width: 50px;
}

.gosoloBookingManageTable .table>thead>tr>th:nth-child(4) {
  min-width: 220px;
}

.goSoloUserRequestForm .geosuggest__input {
  color: #484848 !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  border: 1px solid #dce0e0;
  padding: 10px;
  height: 40px;
}

.goSoloUserRequestForm .geosuggest__suggests--hidden {
  display: none;
}

.goSoloUserRequestForm .geosuggest__suggests {
  left: 0px !important;
  border: 1px solid #dce0e0;
  border-top: 0px;
  margin-top: 1px;
}

.goSoloUserRequestForm .geosuggest__input:focus {
  border-color: #66afe9 !important;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%) !important;
}

.goSoloUserRequestForm .DateInput_input {
  font-size: 14px !important;
  padding: 0px 10px 0px !important;
  border-bottom: none !important;
  height: 40px !important;
  border-radius: 4px;
}

.goSoloUserRequestForm .DateInput {
  width: 30% !important;
  border-radius: 4px !important;
}

.goSoloUserRequestForm .DateRangePickerInput_arrow {
  background-position: 50% 50% !important;
}

.goSoloUserRequestForm .DateRangePickerInput {
  border-radius: 4px !important;
}

.goSoloUserRequestForm .DateInput_fang {
  top: 45px !important;
}

.goSoloUserRequestForm .DateRangePicker_picker {
  top: 56px !important;
}

.goSoloUserRequestForm .css-frv6jl-control:hover {
  border-color: #dce0e0 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.goSoloUserRequestForm .css-1k1e4o2-control:hover {
  border-color: #dce0e0 !important;
  border-radius: 4px !important;
  box-shadow: none;
}

.goSoloUserRequestForm .css-1k1e4o2-control {
  border-color: #dce0e0 !important;
  border-radius: 4px !important;
  box-shadow: none;
}

.goSoloUserRequestForm .css-frv6jl-control {
  border-color: #dce0e0 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.positionRelative {
  position: relative;
}

.goSoloUserRequestCloseBtn,
.goSoloUserRequestCloseBtn:hover,
.goSoloUserRequestCloseBtn:active,
.goSoloUserRequestCloseBtn:focus {
  position: absolute;
  right: -6px;
  top: -12px;
  border-radius: 50%;
  color: #fff !important;
  background: #FF0000 !important;
  border: 0px solid #FF0000 !important;
  padding: 2px 8px;
  font-size: 14px;
  line-height: 20px;
}

.houseRulesPdfDroupZone div.filepicker {
  height: 100%;
  min-height: auto;
  margin: 0px;
  padding: 12px 0px !important;
  border: none;
  border-radius: 0px !important;
  color: #fff !important;
  background-color: #77d1da !important;
}

.houseRulesPdfDroupZone .filepicker .dz-message {
  margin: 0px;
  color: #fff !important;
}

.houseRulesPdfSection {
  border: 2px dashed #77d1da;
  text-align: center;
  margin: 6px 0px 12px;
  padding: 20px;
}

.houseRulesPdfImage {
  width: 100%;
  max-width: 180px;
  margin: 0px auto 18px auto;
  display: block;
}

.houseRulesPdfCloseIcon {
  position: absolute;
  top: -10px;
  background-color: #77d1da;
  border-radius: 50%;
  color: #fff;
  padding: 0px 4px 1px 4px;
  width: 100%;
  max-width: 21px;
  font-weight: 700;
  right: -24px;
  cursor: pointer;
}

.houseRulesPdfCloseIconSection {
  display: inline-block;
  position: relative;
}

.houseRulesPdfText,
.houseRulesPdfText:hover,
.houseRulesPdfText:focus,
.houseRulesPdfText:active {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: #77d1da !important;
}

.pdfLoader>span:first-child {
  width: 19px !important;
  height: 19px !important;
  margin-right: 4px;
}

.houserulesDroupZone {
  position: absolute;
  left: 0;
  right: 0;
  margin-right: 100px;
  z-index: 0;
  display: inline-block;
}

.countryInputPhone .react-tel-input .country-list {
  width: 100% !important;
  border-radius: 0 0 2px 2px !important;
  padding: 16px 0px !important;
  margin: 8px 0 0px 0px !important;
  outline: none;
}

.userMention {
  border-radius: 6px;
  background-color: #D3E1EB;
  padding: 3px 0;
}

/******************* Country Input Start *****************/
.countryInputPhone .react-tel-input input[type=text],
.countryInputPhone .react-tel-input input[type=tel] {
  width: 100% !important;
  height: 40px !important;
  resize: none !important;
  border-radius: 2px !important;
  padding-left: 70px !important;
  z-index: 1 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #484848 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: 1px solid #ced4da;
}

.countryInputPhone .react-tel-input .flag-dropdown {
  background-color: #fff !important;
  border: 1px solid #ced4da !important;
  border-radius: 2px 0 0 2px !important;
}

.countryInputPhone .react-tel-input .country-list .country-name {
  color: #484848 !important;
}

.countryInputPhone .react-tel-input .country-list .country .dial-code {
  color: #767676 !important;
  font-weight: 700;
}

.countryInputPhone .react-tel-input .selected-flag {
  width: 60px !important;
  padding: 0 0 0 0px !important;
  border-radius: 2px 0 0 2px !important;
  border-right: 1px solid #ced4da;
  outline: none !important;
}

.countryInputPhone .react-tel-input .selected-flag .flag {
  left: 20px !important;
}

.countryInputPhone .flag-dropdown.open-dropdown {
  background: #fff;
  border-radius: 2px 0 0 0 !important;
  width: 100% !important;
}

.countryInputPhone .react-tel-input .country-list {
  width: 100% !important;
  border-radius: 0 0 2px 2px !important;
  padding: 16px 0px !important;
  margin: 8px 0 0px 0px !important;
  outline: none;
  text-align: left;
}

.countryInputPhone .react-tel-input .country-list .country:hover {
  background-color: #fff !important;
  outline: none !important;
}

.countryInputPhone .react-tel-input .flag-dropdown {
  width: 100% !important;
}

.countryInputPhone .react-tel-input {
  font-family: inherit !important;
}

.countryInputPhone .react-tel-input .country-list {
  max-height: 130px !important;
}

.countryInputPhone .special-label {
  display: none;
}

/******************* Country Input End *****************/
.profilePhotoCursor .dz-hidden-input {
  cursor: not-allowed;
}

.profilePhotoCursor div.filepicker {
  cursor: not-allowed;
}

.profilePhotoCursor .Loader__message {
  cursor: not-allowed;
}

.goHustlePostManageMent .geosuggest__input {
  height: 34px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: none;
  padding: 6px 12px !important;
  font-size: 14px;
}

.goHustlePostManageMent .geosuggest__item {
  padding: 6px 12px !important;
}

.goHustlePostManageMent .geosuggest__suggests {
  border-top: 0px solid #fff !important;
  border-radius: 0px 0px 4px 4px;
  margin-top: 0px !important;
}

.goHustlePostManageMent .geosuggest__suggests--hidden {
  display: none;
}

.gohustleHeaderSection {
  display: flex;
  align-items: center;
}

.gohustleSubImage {
  width: 100%;
  max-width: 100px;
}

.gohustleSubImageMargin {
  margin-left: 20px;
}

.goHustleLoader .Loader__message {
  left: 70px;
  top: 125px !important;
}

.contentStickHustle p>span,
.contentStickHustle p>strong {
  background-color: transparent !important;
}

.goHustlePostItem p>span,
.goHustlePostItem p>strong {
  background-color: transparent !important;
}

.goHustleExplorePlaceSuggest .geosuggest__input {
  border: 1px solid #ccc;
  box-shadow: none;
  border-radius: 4px;
  height: 39px;
  font-size: 14px;
}

.goHustleExplorePlaceSuggest .geosuggest__input:focus {
  border-color: #66afe9 !important;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%) !important;
}

.goHustleExplorePlaceSuggest .geosuggest__suggests {
  width: 100% !important;
  left: 0px !important;
  border: 1px solid #ccc !important;
  margin: 0px 0px 0px 0px !important;
  border-top: 0px !important;
}

.goHustleExplorePlaceSuggest .geosuggest__suggests--hidden {
  display: none;
}

.infiniteScroolHustle .infinite-scroll-component {
  overflow: visible !important;
}

.goHustleLoaderSection .Loader {
  height: 300px;
}

.noLineHeight {
  line-height: inherit;
}

.applyedJobTable .table>thead>tr>th {
  border-bottom: 1px solid #dce0e0;
}

.applyedJobTable {
  height: 408px;
  overflow: auto;
}

.applyedJobTable .table>thead>tr>th {
  position: sticky;
  top: 0px;
  background-color: #fff;
}

.goTeamCheckBox .checkbox label input[type='checkbox'] {
  margin-top: 4px;
}

.adminBookToalTextSection {
  display: flex;
  align-items: center;
}

.adminBookToalText {
  margin: 0px 6px 8px 0px;
}

.adminBookToalMargin {
  margin: 0px 0px 8px 0px;
}

.noMargin {
  margin: 0px;
}

.gosoloReviewSlider .slick-list {
  height: auto;
  overflow: visible;
  padding: 0 !important;
  margin-top: 30px;
}

.gosoloReviewSlider .slick-slider .slick-track {
  width: 100% !important;
  max-width: 400px;
  margin: 0 auto;
}

.gosoloReviewSlider .slick-initialized .slick-slide {
  width: 100% !important;
  max-width: 400px;
  margin: 0 auto;
}

.adminUserReviewIcons .dv-star-rating .dv-star-rating-non-editable {
  display: flex;
  align-items: center;
}

.adminUserReviewIcons {
  min-width: 200px;
}

.dateGridCss {
  display: grid;
  grid-template-columns: 70% 25%;
}
@import '../variables.css';

.saving {
  font-size: 18px;
  color: #77d1da;
}

.savingDots {
  font-size: 48px;
  line-height: 0px !important;
}

.saving span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  color: #77d1da;
}

.saving span:nth-child(2) {
  animation-delay: 0.2s;
}

.saving span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.textCenter {
  text-align: center;
}

.iconColor {
  color: #77d1da;
  font-size: 48px;
}

@import '../variables.css';

.imgBackground {
  background-color: #ececec;
}

.editProfilePic {
  float: right;
}

.profileIconSize {
  height: 20px;
  width: 20px;
}

.editProfilePic {
  position: absolute;
  top: 10px;
  right: 10px;
}

.editProfilePicMarker {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 25px;
  height: 25px;
  background: #000;
  padding-top: 2px;
  border: 0;
  border-radius: 2px;
  opacity: 0.9;
  color: #fff;
  cursor: pointer;
  padding-left: 4px;
}

.editProfilePicMarker:hover,
.editProfilePicMarker:focus,
.editProfilePicMarker:active {
  background: #000;
  color: #fff;
}

.displayBlock {
  display: block;
}
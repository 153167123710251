@import '../variables.css';

.root {
  padding: 32px;
}

.container {
  margin: 0 auto;
  padding: 0px;
  max-width: 380px;
}

/** RentAll - Begin **/

.logInModalContainer {
  max-width: 450px;
}

.logInModalBody {
  padding: 0px;
}

.lineThrough {
  position: relative;
  z-index: 1;
  display: block;
  margin: 12px 0px;
  width: 100%;
  color: #767676;
  text-align: center;
  font-size: 14px;
}

.lineThrough::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  margin-top: -5px;
  margin-left: -20px;
  width: 40px;
  height: 10px;
  background-color: #fff;
  content: '';
}

.lineThrough::after {
  position: absolute;
  top: 49%;
  z-index: -2;
  display: block;
  width: 100%;
  border-bottom: 1px solid #ddd;
  content: '';
}

.formGroup {
  margin-bottom: 6px;
}

.formSection {
  overflow: hidden;
}

.noPadding {
  padding: 0px;
}

.textAlignRight {
  text-align: right;
}

.textAlignLeft {
  text-align: left;
}

.modalCaptionLink {
  color: #77d1da;
}

.modalCaptionLinkLarge {
  font-size: 16px;
  line-height: 32px;
}

.modalCaptionLink:hover,
.modalCaptionLink:focus {
  color: #77d1da;
  text-decoration: underline;
}

.button {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 16px;
  width: 100%;
  outline: 0;
  border: 1px solid #373277;
  border-radius: 4px;
  background: #373277;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.3333333;
  cursor: pointer;
}

.btnPrimary {
  border-color: #77d1da;
  background-color: #77d1da;
  color: #fff;
}

.btnPrimary:hover,
.btnPrimary:focus {
  border-color: #49d5dc;
  background-color: #49d5dc;
  color: #fff;
}

.btnPrimaryBorder {
  border-color: #77d1da;
  color: #77d1da;
  background-color: #fff;
}

.btnPrimaryBorder:hover,
.btnPrimaryBorder:focus {
  border-color: #49d5dc;
  color: #49d5dc;
  background-color: #fff;
}

.btnSmall {
  padding: 5px 0px;
}

@media screen and (max-width: 640px) {
  .logInModalContainer {
    margin: 0px auto;
  }
}

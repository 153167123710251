:root {
  --font-family-base: 'Segoe UI', 'HelveticaNeue-Light', sans-serif;
  --max-content-width: 1080px;
  --max-container-width: 100%;
  --screen-xs-min: 480px;
  --screen-sm-min: 768px;
  --screen-md-min: 992px;
  --screen-lg-min: 1200px;
  --border-color: #dce0e0;
  --background-color: #ffffff;
  --btn-color: #484848;
  --btn-primary-bg: #77d1da;
  --btn-primary-color: #ffffff;
  --btn-primary-hover-bg: #58595b;
  --btn-secondary-bg: #49d5dc;
  /* --btn-secondary-bg: rgb(0, 132, 137); */
  --btn-secondary-color: #ffffff;
  --btn-secondary-hover-bg: rgb(119, 209, 218);
}

@import 'utils.module.css';
@import 'helpers.module.css';

.btn,
.btn:focus {
  box-sizing: border-box !important;
  margin: 0 !important;
  padding: 6px 12px !important;
  width: auto;
  outline: 0 !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 4px !important;
  background: var(--background-color) !important;
  color: var(--btn-color) !important;
}

.btn:hover {
  background: var(--border-color) !important;
  outline: 0 !important;
}

.btnPrimary,
.btnPrimary:focus {
  border-color: var(--btn-primary-bg) !important;
  background-color: var(--btn-primary-bg) !important;
  color: var(--btn-primary-color) !important;
}

.btnPrimary:hover {
  border-color: var(--btn-primary-hover-bg) !important;
  background-color: var(--btn-primary-hover-bg) !important;
  color: var(--btn-primary-color) !important;
}

.btnPrimaryBorder,
.btnPrimaryBorder:focus {
  border-color: var(--btn-primary-bg) !important;
  color: var(--btn-primary-bg) !important;
  background-color: var(--btn-primary-color) !important;
}

.btnPrimaryBorder:hover {
  border-color: var(--btn-primary-hover-bg) !important;
  color: var(--btn-primary-hover-bg) !important;
  background-color: var(--btn-primary-color) !important;
}

.btnSecondary,
.btnSecondary:focus {
  border-color: #77d1da !important;
  background-color: #77d1da !important;
  color: var(--btn-secondary-color) !important;
}

.btnSecondary:hover {
  border-color: #77d1da !important;
  background-color: #77d1da !important;
  color: var(--btn-secondary-color) !important;
}

.blackColor,
.blackColor:focus {
  border-color: #77d1da !important;
  background-color: #77d1da !important;
  color: #000 !important;
}

.blackColor:hover {
  border-color: #77d1da !important;
  background-color: #77d1da !important;
  color: #000 !important;
}

.btnLink,
.btnLink:focus {
  box-sizing: border-box !important;
  margin: 0 0 0 10px !important;
  padding: 0px !important;
  width: auto !important;
  outline: 0 !important;
  background: #ffffff !important;
  color: #77d1da !important;
  font-weight: 500;
  word-wrap: break-word;
  line-height: 18px;
  letter-spacing: normal;
  font-size: inherit;
  text-decoration: underline;
  border: 0px !important;
}

.btnLink:hover {
  background: var(--background-color) !important;
  color: 337 ab7 !important;
  outline: 0 !important;
  text-decoration: underline;
  font-weight: 500;
  word-wrap: break-word;
  line-height: 18px;
  letter-spacing: normal;
  border: 0px !important;
}

.btnLinkPrimary,
.btnLinkPrimary:focus {
  background-color: var(--background-color) !important;
  color: var(--btn-primary-bg) !important;
}

.btnLinkPrimary:hover {
  background-color: var(--background-color) !important;
  color: var(--btn-primary-bg) !important;
}

.btnLinkSecondary,
.btnLinkSecondary:focus {
  background-color: var(--background-color) !important;
  color: #77d1da !important;
}

.btnLinkSecondary:hover {
  background-color: var(--background-color) !important;
  color: var(--btn-secondary-bg) !important;
}

.searchFilterPopover {
  position: absolute;
  top: 53px;
  left: 0px;
  right: auto !important;
  z-index: 55;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: normal;
  display: inline-block;
  max-height: 55vh;
  visibility: visible;
  background: var(--background-color);
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.2);
  border-image: initial;
  border-radius: 4px;
}

.searchFilterPopoverFull {
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: normal;
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 61%;
  height: initial;
  box-shadow: none;
  visibility: visible;
  top: 148px;
  background: var(--background-color);
  border-radius: 4px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}

.searchFilterPopoverOverlay {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 64px;
  z-index: 2;
  background: rgba(255, 255, 255, 0.85);
  left: 0;
  right: 0;
  bottom: 0;
}

.searchFilterPopoverContent {
  min-width: 370px;
  padding: 24px;
}

.searchFilterCloseIcon {
  font-size: 30px;
  color: #767676;
}

.searchFilterPopoverFooter {
  width: 100%;
  position: relative;
  line-height: 0;
  font-weight: 600;
  padding: 15px;
  background-color: #ffffff;
  z-index: 10;
}

.displayTable {
  display: table;
  width: 100%;
  vertical-align: middle;
}

.displayTableRow {
  display: table-row;
  vertical-align: middle;
}

.displayTableCell {
  display: table-cell;
  vertical-align: middle;
}

.displayInlineBlock {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.fullWidth {
  width: 100% !important;
}

.captionTitle {
  font-size: 16px;
}

.showTabletSection {
  display: none !important;
}

.textAlign {
  text-align: right;
  padding: 0 12px 0 0;
}

.key img {
  width: 32px;
  margin: -2px 0 0 5px;
}

.keyWhite img {
  width: 32px;
  margin: -2px 0 0 5px;
}

@media screen and (max-width: 768px) {
  .searchFilterPopoverFull {
    width: 100%;
    height: 100vh;
    top: 0px;
    right: 0 !important;
    box-shadow: none;
    border: 1px solid #fff;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    z-index: 99999;
  }

  .searchFilterPopoverContent {
    min-width: 320px;
    padding: 68px 15px 70px !important;
    height: 100%;
    min-height: 100vh;
    -webkit-overflow-scrolling: touch;
  }

  .searchFilterPopoverHeader {
    position: fixed;
    top: 0;
    z-index: 7;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px 15px;
    background: #fff;
    display: block !important;
  }

  .searchFilterPopoverFooter {
    font-weight: 600;
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    padding: 15px;
    background-color: var(--background-color);
    z-index: 10;
  }

  .showTabletSection {
    display: block !important;
  }

  .hideTabletSection {
    display: none !important;
  }

  .searchFilterPopoverContentDate {
    padding: 50px 15px 70px !important;
  }
}

.capitalizeText {
  text-transform: capitalize;
}

@media screen and (max-width: 991px) {
  .responsiveFooter {
    position: relative;
  }

  .responsiveContent {
    min-height: 100% !important;
  }
}

/*Arun pandi Home page degin chnages */

@media screen and (min-width: 1200px) {
  .HomeContainer {
    min-width: 1200px;
    max-width: 1450px;
    width: 100%;
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .firstBlock {
    width: 89% !important;
  }

  .bx {
    padding: 20px 9% 25px !important;
  }

  h3.bx_title {
    font-size: 34px !important;
    margin-bottom: 0.8rem !important;
    line-height: 1;
    padding-right: 10px;
  }

  .bx_typo {
    font-size: 14px !important;
    line-height: 1.3 !important;
  }

  .enterText {
    font-size: 28px !important;
  }

  .keyImage {
    height: 37px !important;
  }

  .responsivePaddingLeft {
    padding-left: 24px !important;
  }

  .ourCityHeader {
    font-size: 27px !important;
    line-height: 0.6 !important;
  }

  .ourCityTwo {
    margin: 22px 0 0 0 !important;
  }

  .ourLeft {
    height: 229px !important;
    min-height: 229px !important;
  }

  .alignCenter img {
    padding-top: 65px !important;
  }

  .ourContent {
    font-size: 23px;
    text-shadow: 0px 0px 18px #000 !important;
  }

  .marginTop10 {
    margin-top: 15px !important;
  }

  .firstBlock {
    width: 88%;
  }

  .ourCityTwo {
    margin: 23px 0 0 0;
  }

  .ourLeft {
    height: 232px;
  }

  .ourCitiesGroup {
    padding-right: 0px;
    padding-left: 13px;
    width: 24.8%;
  }

  .HomeGrid {
    padding: 16px 27px;
  }

  .firstBlock {
    min-width: 296px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ourCityTwo {
    margin: 15px 0 0 0 !important;
  }

  .noPaddingRight {
    padding-top: 15px;
  }

  .ourCityTwo.outcitysecond {
    margin-top: 0px !important;
  }
}

.textDecoration {
  text-decoration: none !important;
}

@media screen and (max-width: 767px) {
  .searchBtnWidth,
  .searchBtnWidth:active,
  .searchBtnWidth:focus,
  .searchBtnWidth:hover {
    width: 100%;
  }

  .responsiveClear,
  .responsiveClear:active,
  .responsiveClear:focus {
    width: 25% !important;
    margin: 0 auto !important;
  }

  .searchFilterPopoverOverlay {
    z-index: 0;
  }
}

@media (max-width: 1199px) and (min-width: 769px) {
  .searchBtnWidth,
  .searchBtnWidth:active,
  .searchBtnWidth:focus {
    width: 100%;
  }

  .responsiveClear,
  .responsiveClear:active,
  .responsiveClear:focus {
    width: 20% !important;
    margin: 0 auto !important;
  }

  .searchFilterPopoverPrice {
    left: auto;
    right: 0 !important;
    z-index: 5;
  }

  .searchFilterPopoverFilter {
    top: 210px !important;
  }

  .searchFilterPopoverFull {
    width: 61%;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .searchFilterPopoverFooterDate {
    display: block;
  }
}

.roomWidth {
  width: 33.3%;
  display: inline-block;
  padding: 0px 24px;
  vertical-align: middle;
  margin-bottom: 12px;
}

.noPaddingLeftRoom {
  padding-left: 0px;
}

@media (max-width: 480px) and (min-width: 320px) {
  .responsiveClear,
  .responsiveClear:active,
  .responsiveClear:focus {
    width: 50% !important;
    margin: 0 auto !important;
  }
}

@import '../variables.css';

.root {
  background: #fff;
  color: #333;
  padding: 0px;
  position: relative;
}

.container {
  margin: 0 auto;
  padding: 0px 0 14px 0;
  max-width: var(--max-container-width);
  border-top: 1px solid #dce0e0;
}

.footerSectionContainer {
  max-width: var(--max-content-width);
  margin: 0 auto;
  position: relative;
  padding: 48px 15px;
  display: block;
  line-height: 1.43;
  font-size: 14px;
}

.dropDownSection {
  padding-left: 0px;
}

.noPadding {
  padding: 0px;
}

.pullRight {
  float: right;
}

.horizontalLineThrough {
  position: relative;
  z-index: 0;
  display: block;
  margin: 15px 0px;
  width: 100%;
  color: rgba(24, 24, 24, 1);
  text-align: center;
  font-size: 14px;
  max-width: 130px;
  border-color: rgba(24, 24, 24, 1) !important;
}

.shareIcon {
  color: #767676;
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  float: right;
  font-size: 15px;
  cursor: pointer;
}

.shareIcon:hover,
.shareIcon:hover:focus .shareIcon:hover:focus {
  color: #767676;
}

.textLink {
  color: #484848;
  font-weight: normal;
  padding: 0px 0px;
  margin: 0px;
  line-height: 1.1;
  cursor: pointer;
}

.textLink:hover,
.textLink:focus,
.textLink:hover:focus {
  color: #767676;
}

.footerLink {
  padding: 4px 5px;
}

.formControlSelect {
  height: 53px;
  border-radius: 2px;
  margin-bottom: 8px;
  font-size: 19px;
  display: block;
  overflow: hidden;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  color: #484848;
  line-height: 24px;
  /*Drop Down Caret Icon*/
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 6px, 5px 6px, 1px 1.5em;
  background-repeat: no-repeat;
  /*Drop Down Caret Icon*/
}

.listContainer {
  padding: 0px;
  margin-top: 16px;
  list-style-type: none;
}

.landingLabel {
  color: #484848;
  font-size: 15px;
  font-weight: 700;
  display: block;
  line-height: 18px;
}

.footerLogo img {
  width: 8%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  margin: 0 5px 4px 0;
}

.textMuted {
  color: #767676;
  cursor: pointer;
  font-size: 14px;
}

.myposthd {
  color: rgb(73, 213, 220) !important;
  font-size: 20px !important;
  font-weight: 500;
  text-align: left;
  display: block;
  padding: 4px 0 8px 0px;
  text-decoration: none !important;
}

.pointer {
  color: rgb(73, 213, 220) !important;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .xsHidden {
    display: none;
  }

  .dropDownSection {
    padding-right: 20px;
  }

  .copyrightSection {
    padding: 0px 15px;
  }

  .footerSectionContainer {
    padding: 33px 15px 30px;
  }
}

@media screen and (max-width: 640px) {
  .dropDownSection {
    padding: 0;
    padding-bottom: 15px;
    position: relative;
    z-index: 0;
    margin-top: 10px;
  }

  .noPadding {
    padding-left: 2px;
  }

  .footerSectionContainer {
    padding: 33px 15px 100px;
  }

  .mobileHeight {
    height: auto;
  }

  .copyrightSection {
    text-align: center;
    padding: 0px 0px;
  }

  .horizontalLineThrough {
    margin: 15px auto 15px auto;
  }
}

.btnSection {
  width: 100%;
  max-width: 200px;
  float: right;
}

.fontWeight {
  font-weight: 500;
}

.reverseFlex {
  display: flex;
  flex-wrap: wrap;
}

.reverseFlex:before,
.reverseFlex:after {
  display: none !important;
}

.currencyText {
  color: #484848;
  font-size: 15px;
  font-weight: 700;
  display: block;
  line-height: 18px;
  margin-bottom: 20px;
}

.paddingNone {
  padding: 0 !important;
}

.socialImg {
  width: 22px;
  max-width: 22px;
  margin: 0px 8px;
  vertical-align: middle;
  padding-bottom: 4px;
}

.socialImgLink {
  display: inline-block;
}

.newSup {
  background: #77d1da;
  color: #fff;
  padding: 4px;
  font-size: 9px;
  top: -3px;
  border-radius: 4px;
  font-weight: 600;
  margin-left: 6px;
}

@media (max-width: 767px) {
  .reverseFlex {
    flex-wrap: wrap-reverse;
  }

  .socialImg {
    margin: 0px 4px;
  }
}

@media (max-width: 991px) {
  .btnSection {
    max-width: 100%;
  }

  .paddingLeftNone {
    padding-left: 0 !important;
    padding-right: 15px !important;
  }

  .paddingRightNone {
    padding-right: 0 !important;
    padding-left: 15px !important;
  }
}

@media (min-width: 1200px) {
  .footerSectionContainer {
    min-width: 1200px;
    max-width: 1450px;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
}
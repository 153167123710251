@import '../../variables.css';
@import '../../utils.module.css';

/** RentAll - Begin **/

.formGroup {
  margin-bottom: 6px;
}

.formControlInput {
  padding: 10px 35px 10px 10px;
  height: 64px;
  border-radius: 2px;
  font-size: 16px;
}

.formSection {
  overflow: hidden;
  font-size: 19px;
}

.noPadding {
  padding: 0px;
}

.textAlignRight {
  text-align: right;
}

.textAlignLeft {
  text-align: left;
}

.modalCaptionLink {
  color: #77d1da;
}

.modalCaptionLinkLarge {
  font-size: 16px;
  line-height: 36px;
}

.modalCaptionLink:hover,
.modalCaptionLink:focus {
  color: #77d1da;
  text-decoration: underline;
}

.button {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 7px 21px;
  width: 100%;
  outline: 0;
  border: 1px solid #373277;
  border-radius: 4px;
  background: #373277;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.3333333;
  cursor: pointer;
}

.button:hover {
  background: rgba(54, 50, 119, 0.8);
}

.button:focus {
  border-color: #0074c2;
  box-shadow: 0 0 8px rgba(0, 116, 194, 0.6);
}

.btnPrimary {
  border-color: #77d1da;
  background-color: #77d1da;
  color: #fff;
}

.btnPrimary:hover,
.btnPrimary:focus {
  border-color: #49d5dc !important;
  background-color: #49d5dc !important;
  color: #fff;
}

.btnPrimaryBorder {
  border-color: #77d1da;
  color: #77d1da;
  background-color: #fff;
}

.btnPrimaryBorder:hover,
.btnPrimaryBorder:focus {
  border-color: #49d5dc;
  color: #49d5dc;
  background-color: #fff;
}

.btnSmall {
  padding: 5px 0px;
}

.horizontalLineThrough {
  position: relative;
  z-index: 1;
  display: block;
  margin: 15px 0px;
  width: 100%;
  color: #767676;
  text-align: center;
  font-size: 14px;
}

.leftICon {
  color: #77d1da !important;
  margin-right: 8px;
  position: relative;
  top: 4px;
}

.dispalyTable {
  display: table;
}

.displayCell {
  display: table-cell;
  vertical-align: middle;
}

@media screen and (max-width: 640px) {
  .smSpace {
    margin-top: 8px;
  }
}

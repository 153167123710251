@import '../../components/variables.css';

.container {
  margin: 0px auto;
  padding-top: 30px;
  max-width: var(--max-content-width);
}

.landingContainer {
  max-width: 1080px;
  width: 100%;
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .smPadding {
    padding: 0px;
  }
}

@import '../variables.css';
@import '../utils.module.css';
/** RentAll - Begin **/

.formGroup {
  margin-bottom: 6px;
}

.formControlInput {
  padding: 10px 35px 10px 10px;
  height: 40px;
  border-radius: 2px;
  font-size: 14px;
  border: 1px solid #aaa;
  background-color: #fff;
  color: #484848;
}

select {
  background-position: 96% 5px;
}

.formSection {
  overflow: hidden;
}

.button {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 16px;
  width: 100%;
  outline: 0;
  border: 1px solid #373277;
  border-radius: 4px;
  background: #373277;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.3333333;
  cursor: pointer;
}

.button:hover {
  background: rgba(54, 50, 119, 0.8);
}

.button:focus {
  border-color: #0074c2;
  box-shadow: 0 0 8px rgba(0, 116, 194, 0.6);
}

.facebook {
  border-color: #3b5998;
  background: #3b5998;
  border-radius: 4px;
  text-decoration: none;
  composes: button;
}

.btnPrimary {
  border-color: #77d1da;
  background-color: #77d1da;
  color: #fff;
}

.btnPrimary:hover,
.btnPrimary:focus,
.btnPrimary:active,
.btnPrimary:active:hover,
.btnPrimary:active:focus {
  border-color: #49d5dc !important;
  background-color: #49d5dc !important;
  color: #fff;
}

.btnPrimaryBorder {
  border-color: #77d1da;
  color: #77d1da;
  background-color: #fff;
}

.btnPrimaryBorder:hover,
.btnPrimaryBorder:focus,
.btnPrimaryBorder:active,
.btnPrimaryBorder:active:hover,
.btnPrimaryBorder:active:focus {
  border-color: #49d5dc;
  color: #49d5dc;
  background-color: #fff;
}

.noPadding {
  padding: 0px;
}

.noMargin {
  margin: 0 auto;
}

.noPaddingLeft {
  padding-left: 0 !important;
}

.genderText {
  display: inline-block !important;
  max-width: 100% !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  /* vertical-align: middle !important; */
}

.radioBox {
  margin: 0 auto !important;
  vertical-align: middle;
}

.spaceTop1 {
  margin-top: 6px;
}

.spaceTop2 {
  margin-top: 12px;
}

.eyeStyle {
  /* float: right;
  margin: -30px 10px 0px 0px; */
}

.disPlayTable {
  display: table;
  width: 100%;
}

.disPlayRow {
  width: 100%;
  display: table-row;
}

.disPlayCell {
  width: 100%;
  display: table-cell;
}

.passwordPosition {
  position: relative;
}

.passwordTwo {
  float: right;
  margin: -32px 8px 0px 0;
  position: relative;
  z-index: 3;
  cursor: pointer;
}

.space1 {
  margin-bottom: 6px;
}

.vtnMiddle {
  vertical-align: top;
}

.textPadding {
  padding: 0 0 0 4px;
  display: inline-block;
}

.referalCodeBold {
  font-size: 16px;
  font-weight: bold;
}

.widthRadio {
  width: 20px;
}

.allMember {
  margin-bottom: 5px;
}

.signUpHereCode, .signUpHereCode:hover {
  font-size: 18px;
  text-align: center;
  display: block;
  color: #484848 !important;
}
.socialRulesPadding {
  padding: 30px 20px 30px;
}

.descText {
  font-size: 18px;
  letter-spacing: 0.3px;
  font-weight: 500;
}

.spaceTop3 {
  margin-top: 18px !important;
}

.checkBoxMarginLeft {
  margin-left: 5px;
}

.textAlignRight {
  text-align: right;
}

.goLogo {
  padding-right: 0px;
  margin-bottom: 3px;
}

.lineHeight {
  line-height: 30px;
}

.btnPrimaryBorder {
  border-color: #77d1da !important;
  color: #77d1da !important;
  background-color: #fff !important;
  width: 100%;
  max-width: 200px;
}

.btnPrimaryBorder:hover,
.btnPrimaryBorder:focus,
.btnPrimaryBorder:active,
.btnPrimaryBorder:active:hover,
.btnPrimaryBorder:active:focus {
  border-color: #49d5dc !important;
  color: #49d5dc !important;
  background-color: #fff !important;
}

.vtrMiddle {
  vertical-align: middle;
}

.modelTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.displayTableCell {
  display: table-cell;
}
.checkBoxWidth {
  width: 1%;
  vertical-align: middle;
}
.tableCell {
  display: table-cell;
}
@import '../variables.css';

.notification {
  fill: #77d1da;
  height: 7px;
  width: 7px;
  position: absolute;
  top: 18px;
  transform: translate3d(4px, -8px, 0);
}

.count {
  position: absolute;
  top: -2px;
  right: -5px;
  display: inline-block;
  min-width: 9px;
  padding: 5px 8px;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  color: #fff;
  border: 1px solid #77d1da;
  background: #77d1da;
  z-index: 1;
  border-radius: 50px;
}

@media screen and (max-width: 1199px) {
  .count {
    top: -2px;
    left: 75px;
    right: auto;
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .notification {
    right: 6px;
  }
}
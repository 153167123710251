.listLeft {
  width: 100%;
  background-color: #77d1da;
  height: 430px;
  min-height: 430px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.listright {
  width: 100%;
  height: 430px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.paddingRight {
  padding-right: 0;
}

.paddingLeft {
  padding-left: 0;
}

.bannerImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: #f5f5f5;
  height: 100%;
  width: 55%;
  float: left;
  /* border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; */
  min-height: 470px;
  max-height: 470px;
  position: relative;
}

@import '../variables.css';

.root {
  background: #fff;
  color: #fff;
}

.container {
  margin: 0 auto;
  padding: 0px;
  max-width: 100%;
}

.rentAllHeader {
  background: #fff;
  border-width: 0px 0px 1px 0px;
  border-color: #dce0e0;
  border-radius: 0px;
  border-bottom: 0 !important;
  /* height: 55px; */
  line-height: 76px;
  width: 100%;
  box-shadow: none;
  margin-bottom: 0px;
  z-index: 1000;
}

.rentAllHeaderBorderLess {
  border: 0px;
  box-shadow: 1px -2px 16px 1px #ccc;
  padding-top: 5px;
}

.brand {
  line-height: inherit;
  vertical-align: middle;
  text-decoration: none;
  height: 63px;
  float: none;
  padding: 0px 19px;
  color: #484848;
  font-size: 24px;
  font-weight: normal;
}

.brand:hover,
.brand:focus {
  text-decoration: none;
}

.brandImgToggle {
  padding-left: 12px;
  padding-right: 12px;
}

.navBarToggle {
  color: #484848;
  float: none;
  font-size: inherit;
  line-height: inherit;
  vertical-align: middle;
  border: 0px;
  border-radius: 0px;
  margin: 0px;
  padding: 0px 19px 0px 0px;
  display: table-cell;
}

.geoSuggestContainer {
  width: 100% !important;
  margin: 0px !important;
}

.formControlInput {
  padding: 10px 35px 10px 10px;
  height: 38px;
  border-radius: 2px;
  font-size: 14px;
  border: 1px solid #aaa;
  background-color: #fff;
  color: #767676;
}

.navBarToggle:hover,
.navBarToggle:focus,
.navBarToggle:hover:focus {
  background: none;
}

.navBarToggle {
  display: none;
}

.fixedHeader {
  position: fixed;
  width: 100%;
  left: 0px;
  right: 0px;
  height: auto;
  top: 0px;
}

.mobileView {
  display: none;
}

.paddingNone {
  padding: 0 !important;
}

.breakPoint {
  margin: 0px;
}

@media (max-width: 991px) {
  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-bottom: 0px !important;
    position: relative;
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .rentAllHeader {
    margin-bottom: 0;
    height: auto;
  }

  .container {
    position: relative;
    z-index: 25;
  }

  .navBarToggle {
    display: block;
  }

  .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-bottom: 0px !important;
    position: relative;
    width: 100% !important;
  }

  .brandImg {
    padding-left: 15px !important;
  }

  .breakPoint {
    /* display: none !important; */
    margin-top: 0px !important;
    /* max-width: 320px; */
  }

  /* .container {
    position: relative;
    z-index: 4;
  } */
}

@media (max-width: 1199px) and (min-width: 1024px) {
  .rentAllHeader {
    margin-bottom: 0;
    height: auto;
  }

  .positionMd {
    max-width: 320px;
    position: absolute;
    top: 22px;
    left: 125px;
  }

  .navBarToggle {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .rentAllHeader {
    height: 70px;
  }

  .navBarToggle {
    color: #77d1da;
  }

  .rentAllHeader {
    line-height: 66px;
    margin-bottom: 0;
  }

  .mobileView {
    display: block;
  }

  .root {
    padding-top: 2px;
  }

  .navBarToggle {
    display: block;
  }
}

@media (max-width: 1200px) {
  .breakPoint {
    /* display: none !important; */
    margin-top: 0 !important;
    max-width: 320px;
    margin-bottom: 0px;
  }

  .showBreakPoint {
    display: block !important;
  }
}

.brandImg {
  margin-top: 10px;
  display: table-cell;
}

/* @media (max-width:1023px) and (min-width:765px) {
.rentAllHeader {

    z-index: inherit !important;
  }
} */
@media (max-width: 767px) {
  .brandImg {
    display: table-cell !important;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 10px;
    width: 100%;
  }

  .displayTable {
    display: table;
    width: 100%;
  }

  .rentAllHeader {
    background: #fff;
    border-width: 0px 0px 1px 0px;
    border-color: #dce0e0;
    border-radius: 0px;
    border-bottom: 0 !important;
    /* height: 55px; */
    line-height: 77px;
    width: 100%;
    box-shadow: none;
    margin-bottom: 0px;
    z-index: 7;
  }
}

@media (min-width: 768px) {
  /* .navBarToggle {
    display: none;
  } */
}

@media (max-width: 767px) {
  .container {
    position: relative;
    z-index: 4;
  }
}

@import '../../common.module.css';

.searchContainer {
  font-size: 19px;
  line-height: 24px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0px;
  table-layout: fixed;
  height: 42px;
  width: 100%;
  position: relative;
  max-width: 400px;
  margin-top: 8px;
}

.searchIconContainer {
  width: 45px;
  padding-left: 12px;
}

.searchIcon {
  height: 24px;
  width: 24px;
  display: block;
  fill: rgb(118, 118, 118);
}

.geosuggest {
  outline: none;
  width: 100% !important;
  margin: 0 !important;
  height: 39px !important;
}

@media screen and (max-width: 1399px) and (min-width: 1200px) {
  .searchContainer {
    max-width: 225px;
  }
}

@media screen and (max-width: 1250px) and (min-width: 1201px) {
  .searchContainer {
    max-width: 200px;
  }
}

@media screen and (min-width: 1400px) {
  .searchContainer {
    max-width: 320px;
  }
}

@media screen and (max-width: 1200px) {
  .searchContainer {
    margin-top: 0px;
  }
}

@media (max-width: 1199px) and (min-width: 1024px) {
  .searchContainer {
    max-width: 230px;
  }
}
